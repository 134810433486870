ul.certificates {
	li {}
	figure {}
	.wrapImg { position:relative; display:block; border:1px solid $clrBrdr; overflow:hidden; margin-bottom:0.5em; transition:border-color ease-in-out $timeF;  }
	img {  }
	figcaption { color:$clrGray; font-size:0.889em; font-family:$fontRC; text-align:center; transition:color ease-in-out $timeF; }
	.layerH { position:absolute; left:0; top:100%; width:100%; height:100%; background:$clrBlackAAA; transition:all ease-in-out $timeF;
		.btn { position:absolute; left:50%; top:50%; display:block; width:auto; transform:translate(-50%,-50%); }
	}
	a { display:block; text-decoration:none;
		&:hover {
			.wrapImg { border:1px solid $clrTxt; }
			figcaption { color:$clrTxt; }
			.layerH {  top:0;  }
		}
	}
}

.brdO {border:1px solid #fabeaa; }