@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i|Roboto:300,300i,400,400i,500,500i,700,700i,900,900i|Montserrat+Alternates:300,400,500,600,700,800,900&display=swap&subset=cyrillic,cyrillic-ext');

html { -webkit-font-smoothing:antialiased; -moz-osx-font-smoothing:grayscale; text-size-adjust:none; }

body { font:400 14px/1.5 $fontR; color:$clrTxt;
	@include respond(tabletS) { font-size:16px; }
	@include respond(desktopS) { font-size:18px; }
}

h1,h2,h3 { clear:both; }

h1 { font:400 200%/1.2 $fontR; text-transform:uppercase; margin-bottom:0.5em; }
h1.seriesH { font:400 200%/1.2 $fontRC; text-align:center; margin:1.5em 0 0;
	strong { display:block; font-weight:400; color:$clrOrange; }
}
h1.articleH { margin:1.5em 0 1em 0;  text-transform:none; text-align:left; }
h1.defaultH { font:400 200%/1.2 $fontRC; text-align:center; margin:1.5em 0; }
h2 { font:700 170%/1.2 $fontR; text-transform:uppercase; margin-bottom:1.5em; padding-top:1em; }
h2.styleH2, .styleH2 { font:400 170%/1.1 $fontR; text-transform:uppercase; margin-bottom:1.5em; padding-top:1em; text-align:left; color:$clrTxt;
	&:first-letter { color:$clrOrange; font-size:1.05em; }
	&::after { content:""; display:block; width:100%; height:3px; background: linear-gradient(90deg, rgba(233,79,19,1) 0%, rgba(156,157,157,0.2) 100%); }
}
h2.bb { font:400 170%/1.2 $fontRC; border-bottom:3px solid $clrBrdr;
	strong { font-weight:400; color:$clrOrange; }
}
h3 { font:400 130%/1.2 $fontRC; text-transform:uppercase; margin-bottom:0.5em; }
h3.large { font:400 150%/1.2 $fontRC; text-transform:uppercase; padding-top:1em; margin-bottom:1em; }
h3.headerItem { display: inline-block; position:relative; font:400 140%/1 $fontRC; text-transform:uppercase; margin:0 0 -1em; padding: 0.5em 1.5em 0.5em 1.5em; color:#fff; background:$clrOrangeL; opacity:0.9; border-radius:2px 1em 1em 2px;
	&::before { content:''; position:absolute; left:0.5em; top:50%; width:0.6em; height:0.6em; margin-top:-0.3em; border:3px solid #fff; border-radius:50%; }
}

h4 { font:400 111%/1.2 $fontRC; text-transform:uppercase; margin-bottom:0.5em; }
h5 { font:700 100%/1.2 $fontRC; text-transform:uppercase; margin-bottom:0.5em; }

p { margin-bottom:1.5em; }

.txtThin { font-weight: 300; }

dl {display:flex; flex-flow:row wrap; margin-bottom:0.5em; flex:auto; clear:both; }
dl dt { flex: 0 0 auto; margin-right:1em; }
dl dd { flex: 1 1 auto; font-weight:700; }

ul { @include listR(); margin:0 0 2em 0;
	li { display:block; position:relative; margin-bottom:0.75em; padding-left:1.5em;
		&::before { content:''; position:absolute; left:0; top:0.33em; display:block; width:0.75em; height:0.75em; border:2px solid $clrOrange; border-radius:50%; }
	}
}
ol { @include listR(); counter-reset:list; padding-left:1.5em; margin-bottom:2em;
  li { position:relative; margin:0 0 0.125em 0; list-style-type:none; line-height:1.2; padding:0.4em 0; display:inline-block; width:100%; vertical-align:top; }
  > li { padding-left:0.5em;
	  &:before { counter-increment:list; content:counter(list)"."; position:absolute; top:0; right:100%; width:1.5em; height:2em; color:$clrOrange; line-height:2; font-weight:700; text-align:center; }
	  ol { counter-reset:list2; margin:0; padding:0.5em 0 0 2em;
		li {
			&:before { counter-increment:list2; content:counter(list)"."counter(list2)"."; position:absolute; top:0; right:100%;  width:auto; }
			ol { counter-reset:list3; margin:0; padding:0.5em 0 0 2.5em;
				li {
					&:before { counter-increment:list3; content:counter(list)"."counter(list2)"."counter(list3)"."; position:absolute; top:0; right:100%; }
				}
			}
			ul { @include listR(); margin:0; padding-top:0.75em;
			li { display:block; position:relative; margin-bottom:0.75em; padding-left:1.5em;
					&::before { counter-increment:none; content:''; position:absolute; left:0; top:0.25em; display:block; width:0.75em; height:0.75em; border:2px solid $clrOrange; border-radius:50%; }
				}
			}
		}
	  }
  }
}
ul.noMarkers { @include listR(); margin-bottom:2em;
	li { margin-bottom:0.25em; }
}
p.prevList { margin-bottom:0.25em;}

a, .linkType { color:$clrLink; text-decoration:underline; cursor:pointer;
	&:hover { color:$clrOrange; }
	&:focus { outline:none; }
	img { border:none; }
}
.dotted { @extend a;
	.bd { display:inline-block; line-height:1; white-space:nowrap; border-bottom:1px dotted; }
}

.floatL-img { text-align: center; margin:0 0 2em 0; @include clearfix();
	@include respond(tabletS) { float:left; clear:left; margin:0 2em 2em 0; }
}
.floatR-img { text-align: center; margin:0 0 2em 0; @include clearfix();
	@include respond(tabletS) { float:right; clear:right;  margin:0 0 2em 2em; }
}
.clearfix { @include clearfix(); }

.boxCenter { margin-left:auto;  margin-right:auto; }

.alignL { text-align:left; }
.alignR { text-align:right; }
.alignC { text-align:center; }

.columnTwo {column-count:2; column-gap:2em;  margin-bottom:2em;}


.nowrap { white-space:nowrap; }
.clrOrange { color:$clrOrange; }
.fontThin { font-weight:300; }

.fontItalic { font-style:italic; }

.fontBig { font-size:110%; }
.fontSmall { font-size:85%; }

.tableWrap { clear:both; display:block; margin:0 0 2em 0; padding:1em 0; font-size:90%; overflow-x:auto;
	table { width:100%; border-collapse:collapse;
		th, td { padding:0.5em 1em; border-right:3px solid #fff; border-left:3px solid #fff;  }
		thead th { background:$clrTxt; color:#fff; border-top:none; border-bottom:3px solid #fff; }
		thead {
			th { text-align:center;
				&:first-child { text-align:left; text-transform:uppercase; font-weight: 400; }
			}
		}
		tbody th, tbody td { border-bottom:1px solid $clrBrdr; }
		tbody {
			th { text-align:left;
				em { color:$clrOrange;}
			}
			td { text-align:center; border-bottom:1px solid $clrBrdr;
				&:first-child { text-align:left; }
			}
			tr.alignC {
				td {
					&:first-child { text-align:center; }
				}
			}
			tr:hover {
				th:not([rowspan]), td:not([rowspan]) { background:$clrGrayW; }
			}
		}
		&.alignC {
			thead {
				th { text-align:center; text-transform:none; font-weight:400;
					&:first-child { text-align:center; text-transform:none; font-weight:400; }
				}
			}
			tbody {
				td {
					&:first-child { text-align:center; }
				} 
			} 
		}
	}
}

figure {
	figcaption { font-size:0.889rem; line-height: 1.3; color:$clrGrayD; font-style:italic; text-align: right; }

}
figure.imgTxt { margin-bottom:2em;
	figcaption { font-size:0.889em; color:$clrGray; font-style:italic; text-align: right; }
}

.boxBG { margin-bottom:2em; padding:1em 1em 0.75em; background:rgba(245,245,245,0.5);
	p, ul { margin-bottom:0.5em  }
}

blockquote { padding:1em; margin-bottom:2em; border-top:1px solid $clrBrdr; border-bottom:1px solid $clrBrdr; font-style:italic; font-size:1.11em; }
.indent { display: block; height: 0; padding-top:2rem; }