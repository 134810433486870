.articleFull { position:fixed; z-index:10; top:0; right:0; bottom:0; left:0; width:0; height:0; overflow:hidden; transform:scale(0); opacity:0; transition:transform 400ms ease-in-out, opacity 400ms ease-in-out; background:#fff; text-align:center; white-space:nowrap;

	.closeAF { position:absolute; z-index:3; right:0; top:0; width:40px; height:40px; padding:10px; cursor:pointer;
		i { position:relative; display:block; width:20px; height:20px;
			&::before, &::after { content:''; position:absolute; left:50%; top:0;  width:2px; height:100%; background:$clrGray; }
			&:before { transform:skew(-45deg, 45deg); }
			&:after { transform:skew(45deg, -45deg); }
		}
		&:hover i {
			&::before, &::after { background:$clrOrange; }
		}
	}
	&:before { content:''; display:inline-block; width:0; height:100%; vertical-align:middle; margin-left:-0.4em; }
	&.active {  width:100%; height:100%; transform:scale(1); opacity:1; overflow:scroll; overflow-x:auto; }
	.container { display:inline-block; width:100%; padding-top:2em; padding-bottom:2em; max-width:1200px; vertical-align:middle; white-space:normal; text-align:left; }
	h3:not(.headerItem) { padding-top:1em; }
	h4.txtR { margin-right:25px; }



}