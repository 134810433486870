.popupContent { display:none; }

.blind { position:fixed; z-index:1000; top:0; right:0; bottom:0; left:0; display:none; width:100%; height:100%; overflow-x:hidden; overflow-y:auto; white-space:nowrap; text-align:center;  background:rgba(23,23,23,0.5);
  &:before { content:''; display:inline-block; width:0; height:100%; vertical-align:middle; }
}

.popup { position:relative; display:inline-block; width:100%; vertical-align:middle; background:none; white-space:normal; text-align:left; cursor:default;
	.popupContent { position:relative; display:block; width:100%; padding:2em; background:#fff;
	}

	.close { position:absolute; right:0; top:0; width:40px; height:40px; padding:10px; cursor:pointer;
		i { position:relative; display:block; width:20px; height:20px;
			&::before, &::after { content:''; position:absolute; left:50%; top:0;  width:2px; height:100%; background:$clrGray; }
			&:before { transform:skew(-45deg, 45deg); }
			&:after { transform:skew(45deg, -45deg); }
		}
		&:hover i {
			&::before, &::after { background:$clrOrange; }
		}
	}


	&.popupGallery { min-width:200px; min-height:200px; max-width:96%;
		.popupContent {
			img { display:block; margin:0 auto; max-width:100%; height:auto; }
			.title { font-size:14px; color:$clrBlack; padding-top:4px; text-align:center; }
		}
		.close { z-index:3; }
	}

	@include respond(phoneL) { width:90%; min-width:320px; }
	@include respond(tabletS) { width:auto; min-width:600px; max-width:600px; padding:20px 0;
		.popupContent { width:auto; box-shadow:0 0 3px rgba(0,0,0, 0.9); }
		.close { top:20px; }
	}
}

.arrowPopup { position:absolute; top:0; display:block; width:50%; height:100%; cursor:pointer;
	i { position:absolute; top:50%; width:12px; height:32px; margin-top:-16px;
		&::before, &::after {content:''; position:absolute;  width:4px; height:50%; background:$clrGray; }
		&::before { top:auto; bottom:50%; }
		&::after { top:50%; bottom:auto; }

	}
	&.arrowL { left:0;
		i { left:0.6em;
			&:before { left:3px; transform:skew(-27deg, 0deg); }
			&:after { left:3px; transform:skew(27deg, 0deg); }
		}
	}
	&.arrowR { right:0;
		i { right:0.6em;
			&:before { right:5px; transform:skew(27deg, 0deg); }
			&:after { right:5px; transform:skew(-27deg, 0deg); }
		}
	}
	&:hover, &:active {
		i{
			&::before, &::after { background:$clrOrange; }
		}
	}
}