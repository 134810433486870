.slider2 { margin:0 -10px 2em -10px; padding:0 10px;
	.slick-slide { margin:0 10px; }
	.slick-track { display:flex; align-content:stretch; };
	.slick-arrow { position:absolute; z-index:2; top:0; bottom:0; width:20px; border:none; cursor:pointer; overflow:hidden; text-indent:-2000px; background:#fff;
		&:before, &:after {content:''; position:absolute;  width:4px; height:16px; background:$clrGray; }
		&:before { top:auto; bottom:50%; }
		&:after { top:50%; bottom:auto; }
		&:hover:before,&:hover:after { background:$clrOrange; }
	}
	.slick-prev { left:0;
		&:before { left:5px; transform:skew(-27deg, 0deg); }
		&:after { left:5px; transform:skew(27deg, 0deg); }
	}
	.slick-next { right:0;
		&:before { right:5px; transform:skew(27deg, 0deg); }
		&:after { right:5px; transform:skew(-27deg, 0deg); }
	}

	@include respond(tabletL) {  padding:0; margin:0 -20px 2em -20px;
	  .slick-slide { margin:0 20px; }
	}
}


.slider-aside { margin:0 -10px 1em -10px;
  .slick-slide { margin:0 10px; }
  .slick-track { display:flex; align-content:stretch; };
  .slick-arrow { position:absolute; z-index:2; top:0; bottom:0; width:20px; border:none; cursor:pointer; overflow:hidden; text-indent:-2000px; background:#fff; outline:none;
	&:before, &:after {content:''; position:absolute;  width:4px; height:16px; background:$clrGray; }
	&:before { top:auto; bottom:50%; }
	&:after { top:50%; bottom:auto; }
	&:hover:before, &:hover:after { background:$clrOrange; }
  	&::-moz-focus-inner { border: 0; }
  }
  .slick-prev { left:0;
	&:before { left:5px; transform:skew(-27deg, 0deg); }
	&:after { left:5px; transform:skew(27deg, 0deg); }
  }
  .slick-next { right:0;
	&:before { right:5px; transform:skew(27deg, 0deg); }
	&:after { right:5px; transform:skew(-27deg, 0deg); }
  }
	@include respond(tabletL) { padding:0;  margin:0 -20px 1em -20px;  
		.slick-slide { margin:0 20px; }
	}
}