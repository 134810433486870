// ul.seriesList {
// 	li { }
// }
.series { display:block; text-decoration:none; position:relative; height:100%; cursor:pointer; border:1px solid $clrBrdr; padding:0 1em 1em 1em;
	.img-series { overflow:hidden; margin: 0 -1em 0.5em;
		img { margin:0 auto; opacity:0.9; transform:scale(1); /*transform:scale(0.95);*/ transition:opacity ease-in-out $timeF, transform ease-in-out $timeF; }
	}
	.txt-series  { padding-bottom:30px; font-family:$fontRC;
		@include respond(desktopS) { padding-bottom:40px; }
		p, ul { margin-bottom:0.5em; color:$clrTxt;  }
	}
	.rowBtn { position:absolute; left:1em; right:1em; bottom:1em; text-align:right; margin:0; }

	.options-series { position:static; margin-bottom:0.5em;
		.title-series { position:absolute; left:0; top:0; padding:0.25em 1em; margin:-1px 0 2px -1px;  background:$clrOrangeL; opacity:0.95; transition:opacity ease-in-out $timeF;
			h3 { margin:0; padding:0; font:300 1.11em/1.2 $fontRC;  color:#fff;  text-transform:uppercase;
				.sn { font-weight:400; font-size:1.22em; margin-left:0.25em; font-family:$fontR; text-transform:none; }
			}

		}
		ul { @include listR(); font:300 0.889em/1 $fontRC; color:$clrTxt; transition:color ease-in-out $timeF; margin-right:-1em;  margin-left:-1em;
			li { padding:0.33em 1em 0.33em 1em; background:rgba(245,245,245,0.5); margin-bottom:2px; transition:background ease-in-out $timeF;
				strong { font-weight:400; }
			}
		}
	}


	&:hover {
		.img-series {
			img {  opacity:1; transform:scale(1.05); }
		}
		.options-series {
			ul { color:$clrBlack;}
		}
	}
	/*@include respond(tabletL){
		.options-series { position:absolute; left:0; top:0;
			.title-series { position:relative; }
			ul { margin-right:0;  margin-left:0; font-size:0.778em;
				li { background:rgba(255,255,255,0.6); }
			}
		}
		&:hover {
			.options-series {
				.title-series { opacity:1;}
				ul {
					li {background:rgba(255,255,255,0.9); }
				}
			}
		}
	}*/
}

.img-seriesFA { display:block; max-width:100%; margin:0 auto 1em;
	@include respond(phoneL) { /*max-width:80%;*/max-width:100%; }
	img { @include imgResp(); }
}

.anchorSeries { width:0; height:0; position:relative; top:-20px;
	@include respond(tabletS) { top:-40px; }
}

.itemSeries { /*margin-top:4em;*/ margin-top:3em;
	&:first-child { margin-top:0; }
}