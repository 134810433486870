*, *:before, *:after { margin:0; padding:0; box-sizing:border-box; outline:none; }
html { height:100%; text-size-adjust:none; }
body { min-height:100%; display:flex; flex-direction:column; background:#ffffff;}
.noScroll { overflow:hidden;
  .no-hiddenscroll & { padding-right:17px;
	  .fix { right:17px; }
  }
}




.content { flex-grow:1; }

.container { @include clearfix; position:relative; max-width:1360px; min-width:320px; margin:0 auto; padding-right:10px; padding-left:10px;
	@include respond(tabletS) { padding-right:20px; padding-left:20px; }
	.pInner .content & { padding-bottom:3em; }
}

.section { padding-bottom:3em; overflow:hidden; }

.boxVideo { position:relative; display:block; width:100%; height:0; overflow:hidden; margin-bottom:2em; background-color:$clrGray;
	iframe { width:100%; height:100%; position:absolute; top:0; right:0; bottom:0; left:0; }
}
.video01 { padding:56.25% 0 0 0; }

.hz { @include hz(); }
.imgResp { @include imgResp(); }

.item-gallery { display:block; overflow:hidden; cursor:zoom-in;
	img {transition:transform ease-in-out $timeF;}
	&:hover {
		img { transform: scale(1.1) }
	}
}

.loading-effect-3 {	width: 100px; 	height: 100px; margin:1em auto 0 auto;
	div {	display:block; width:100%; height:100%; border:2px solid $clrGray;	border-radius:50%;
		> span { background:$clrOrange; width:10px; height:10px; display:block; border-radius:50%; position:relative;	top:-6px; left:50%;	transform:translateX(-50%); transform-origin: 0 54px;	animation:effect-3 2s infinite linear; }
	}
}
@keyframes effect-3 {
	from { transform: rotate(0deg);	}
	to { transform: rotate(360deg);	}
}


.requestBlock { background:$clrGrayW; border-top:1px solid $clrBrdr;
	.container,
	.articleFull & .container { max-width:960px; }
	h3.large { text-align:center; }
}


ul.listFAQ { @include listR();  margin: 0 -10px;
	li { margin:0 0 1em 0; padding:0; list-style:none; background:none; }
	.qFAQ, .aFAQ { position:relative; }
	.qFAQ { display:flex; flex-flow:row nowrap; align-items:center; }
	.iconQ { display:block; position:relative; top:-1px; flex:0 0 auto; width:2.6em; height:2.6em; text-align:center; background:white; border:0.3em solid $clrOrangeL; border-radius:50%; margin-right:1em;
		&::before { content:"?"; font-weight:700; font-style:normal; font-size:2em; line-height:1.05; }
	}
	.txtQ { display:block; flex:1 1 auto; text-transform:uppercase; }
	.aFAQ {  background:#fff; padding:0 0 0 1.2em; }

	.txtA { display:block; border-left:0.2em solid #e4e9f0; padding:0.5em 0 0.5em 1em; font-weight:300; }
	@include respond(tabletS) { margin: 0 -20px; }
}


.b24-form-content, .b24-form-padding-side { padding-left:0 !important; padding-right:0 !important; } 