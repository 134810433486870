.navPanelWrap { position:relative; height:47px; z-index:4; background:$clrBlack;
	@include respond(desktopS) { height:83px; }
}
.navPanel { height:47px; border-bottom:3px solid $clrOrange; background-color:$clrBlackA; transition:top $timeF ease-in-out, background-color 200ms ease-in-out;
	.pIndex & { position:fixed; top:0; left:0; right:0; }
	.no-hiddenscroll .noScroll & { right:17px; }
	&.hide { top:-90px; }
	.container { background:$clrBlack; }

	.logo { display:block; float:left; width:90px; margin:5px 0 0 40px;
		img { display:block; max-width:100%; height:auto; }
	}

	.nav { position:absolute; z-index:-1; left:0; right:0; top:-500px; background:$clrBlack; transition:top $timeF ease-in-out; text-transform:uppercase;
		&.active { top:47px; }
	}
	ul.menu { @include listR(); text-align:center; border-bottom:3px solid $clrOrange; }

	.navItem { display:block; padding:0 22px; font-size:15px; font-weight:400; line-height:44px; color:$clrGray; text-decoration:none; cursor:pointer; transition:background-color 300ms ease-in-out, color 300ms ease-in-out;
		&:hover, &:active { background:$clrOrangeD; color:#fff; }
	}

	.hamburger { position:relative; float:left; width:44px; height:44px; cursor:pointer;
		.line { position:absolute; left:10px; background:$clrGray; width:24px; height:4px; }
		.l-01 { top:12px; }
		.l-02 { top:20px; }
		.l-03 { top:28px; }
		&:hover, &:active {
			.line { background:#fff; }
		}
	}

	.contact { position:absolute; top:0; right:10px; text-align:center;
		.tel { display:inline-block; vertical-align:top; font-weight:700; font-size:17px; line-height:44px; text-decoration:none; color:#fff; white-space:nowrap;
			.pre { color:$clrGray; }
		}
		.dotted { display:none; vertical-align:top; margin-left:1em; font:400 11px/44px $fontRC; text-transform:uppercase; color:$clrGray; white-space:nowrap;
			&:hover, &:active { color:#fff; }
		}
	}

	.triggerSM { 
		.navItem { display: none; }
	}
	ul.subMenu { @include listR(); display:block;
		.navAnc {display:block; padding:10px 22px; font-size:15px; font-weight:400; line-height:1.25; color:$clrGray; text-decoration:none; cursor:pointer; transition:background-color 300ms ease-in-out, color 300ms ease-in-out;
			&:hover, &:active { background:$clrOrangeD; color:#fff; }
		}
	}

	@include respond(phoneL) {
		.logo { display:block; position:relative; float:left; width:90px; margin:5px 0 0 40px; }
		.contact {
			.dotted { display:inline-block; }
		}
	}
	@include respond(tabletS) {
		.navItem { display:block; padding:0 32px; }
		.contact { right:20px; }
	}
	@include respond(desktopS) { height:83px;
		&.fix { background:rgba(0,30,61,0.8); }
		.container { background:none; }
		.logo { width:170px; margin:8px 0.5em 0 0; }
		.hamburger { display:none; }
		.nav { display:block; position:relative; top:18px; z-index:auto; background:transparent; transition:none; margin: 0 180px 0 170px; }
		.navItem { padding:0 0.5em; border-bottom:none; color:$clrGray; font-size:17px;
			&:hover, &:active { background:none; color:#fff; }
		}
		ul.menu { border:none;
			li { display:inline-block; vertical-align:top; border-bottom:none; }
		}
		.contact { text-align:right; top:25px;
			.tel { display:block; font-size:24px; line-height:1;}
			.dotted { margin:0; font-size:13px; line-height:1.5; letter-spacing:1px; }
		}

		.triggerSM { position:relative; 
			&.active { z-index: 10; }
			.navItem { display: block; }
		}
		ul.subMenu { display:none; position:absolute; left:0; top:99%; background-color:$clrBlackA; padding: 1rem; text-align: left;
			li { width:100%; }
			.navAnc {display:block; white-space: nowrap; }
		}

	}
	@include respond(desktopM) {
		.navItem { padding:0 1em; }
	}
}

