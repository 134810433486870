.newsList {}
.previewNews { display:block; text-decoration:none; cursor:pointer;  color:$clrTxt;
	.img-preview { position:relative; display:block;  margin-bottom:0.5em; overflow:hidden; background:$clrTxt;
		img {  transition:transform ease-in-out $timeF; }
	}
	.box-preview { overflow:hidden; padding:0 0.25em; }
	.title-preview {  margin:0 0 0.25em;
		h3 { color:$clrTxt;  margin:0; }
	}
	.time-preview { position:relative; top:-3px; color:$clrGray; font-size:0.889em; }

	.txt-preview { font-family:$fontRC; font-weight:300; transition:color ease-in-out 300ms;
		p { margin-bottom:0.75em; }
	}
	@include respond(phoneL) {
		.img-preview { float:left; width:13em; margin-right:0.75em; }
	}
	&:hover { color:$clrTxt; text-decoration:none;
		.btn { color:$clrBlack; border-color:$clrOrange; background:rgba(255,255,255,0.1); }
		.img-preview {
			img { transform:scale(1.1); }
		}
	}
}
