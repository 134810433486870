.preview-article {
	figure { position:relative; display:block; overflow:hidden; padding-top:150%; max-width:100%; }
	img { position:absolute; left:50%; top:0; display:block; width:auto; height:100%; max-height:100%; transform:translateX(-50%); }
	figcaption { position:absolute; left:0; top:0; width:100%; padding:1em; color:#fff; text-align:center;
		h3 { color:#fff; font-size:1.22em; text-transform:none; text-align:left; opacity:(0.8);  transition:opacity ease-in-out $timeF; @include textShadow();}
	}
	.blackout {position:absolute; left:0; top:0; width:100%; height:100%; background: linear-gradient(180deg, rgba(23,23,23,0.7) 0%, rgba(23,23,23,0.1) 100%); }
	.layerH { position:absolute; left:0; top:100%; width:100%; height:100%; background:$clrBlackAA;/*background:rgba(233,79,19,0.8);*/ padding:1em; transition:all ease-in-out $timeF;
		.btn { @include centerA(); }
	}
	.tags { display:block; padding:0 0.5em;}
	&:hover, &:active {
		h3{ opacity:(1); }
		.layerH {  top:0;  }
	}
}

.columnsContent { display:flex; flex-flow:column;  justify-content:space-between;
	.columnL, .columnR {  padding-bottom:2em; }
	.readAlso { position:relative; max-width:100%; }
	@include respond(tabletL) { flex-flow:row nowrap;
		.columnL, .columnR { flex: 1 1 auto; padding-bottom:2em; }
		.columnL { margin:0 3em 0 0; }
		.columnR { flex: 0 0 25%; max-width:25%; padding-top:3.3em; }
		.readAlso { position:sticky; top:1em; }
	}
}

.autorBlock { border-top:3px solid $clrBrdr; text-align:right; padding-top:0.5em; text-align:left; @include clearfix(); }
.autor-article { @include hz(); /*border:1px solid $clrBrdr; background:$clrGrayW; padding:0.5em; max-width:50%;*/
  .avatar-autor { flex:0 0 auto; width:3em; height:3em; margin-right:0.5em; border-radius:50%; border:3px solid $clrOrange; background:$clrGray; overflow:hidden; }
  .name-autor {  flex:1 1 auto;
	strong { display:block; color:$clrTxt; font:400 1em/1 $fontRC; }
	span { color:$clrGray;  font:300 0.778em/1  $fontRC; }
  }
	@include respond(tabletL) { max-width:none; }
}

.headerArticle { @include clearfix(); margin:2.5em 0 1em 0;
	.autor-article { float:right; }
	h1 { margin:0 0 0.25em 0; clear:both; text-transform:none; text-align:left; }
}

.anons { margin-bottom:2em;
	.img-anons { }
	.txt-anons {
		p, ul { margin:0; }
	}
	@include respond(phoneL) { @include hz();
		.img-anons { width:33.33%; flex:0 0 auto; margin-right:2em; }
		.txt-anons { flex:1 1 auto; font-size:110%; font-weight:700; }
	}
}

.gagArticle { background:$clrBrdr; height:100%; }