.actionBox-02 {
	.actionTxt{top:56%;}
	.actionTD { color:#aaa; font-size:calcFluidFontSize(7, 19, 320, 1920); margin-bottom: 0.125rem;
		strong {color:$clrGray;}
	}
	@include respond(tabletS) { 
		.actionTxt{top:62%;}
		.actionTD { font-size:calcFluidFontSize(7, 21, 320, 1920); margin-bottom: 0.375rem;}
	}
	@include respond(tabletL) {
		.actionTD { margin-bottom: 0.5rem;}
	}
	.actionFootnote { color:#ccc; }
}

.actionBox-03 {
	.actionTD {
		strong {color:#ccc;}
	}
	.actionTW { strong {font-size: 1.5em;} }
	.actionPrice {float:right; font:600 calcFluidFontSize(18, 60, 320, 1920)/1.1 $fontMA; letter-spacing: -0.03em; color:$clrOrange; border-bottom:1px solid #fff;
		@include respond(tabletS) { border-bottom:2px solid #fff;	}
	}
	.actionFootnote { color:$clrTxt; }
}