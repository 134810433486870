.promo { position:relative; @include clearfix; margin-bottom:2em;
	.blackout { position:absolute; left:0; top:0; width:100%; height:100%; background:rgba(23,23,23,0.5); }
	.imgBox { position:relative; height:0; padding-top:36.46%;
		img, video { display:block; }
	}
/*
	.promoBoxTop { position:absolute; top:0; right:0; left:0;  background:rgba(23,23,23,0.5);
		.container { position:relative; margin:0 auto; }
		h1 { padding:0.4em 0; float:left; margin:0; text-shadow:0 1px 1px rgba(0,0,0,0.8); color:rgba(255,255,255,0.8); font:400 130%/1.1 $fontR; }
		p { float: right; text-align:right; margin:0; }

	}

	.promoBox { display:none; position:absolute; right:0; bottom:2em; left:0;
		.container { position:relative; margin:0 auto; }

		p { float: right; text-align:right; margin:0; }
		.btn { color:#fff; text-shadow:0 1px 1px rgba(0,0,0,0.8);
			&:hover, &:active { color:#fff; }
		}
	}
*/
	.promoBoxRight { position:relative; padding:20px; background:$clrBlack;

		h1 { padding:0; float:left; margin:0; text-shadow:0 1px 1px rgba(0,0,0,0.8); color:#fff; font:400 150%/1.3 $fontR; margin-bottom:0.75em; }

		ol.listCN { @include listR(); counter-reset:list; padding-left:2em; margin-bottom:1.5em;
			li { position:relative; margin:0 0 0.75em 0; list-style-type:none; line-height:1.2; padding:0.4em 0; display:inline-block; width:100%; vertical-align:top; padding-left:0.5em;  color:rgba(255,255,255,0.8); text-transform:uppercase;  text-shadow:0 1px 1px rgba(0,0,0,0.8);
				&::before { counter-increment:list; content:counter(list); position:absolute; top:0; right:100%; width:2em; height:2em; color:rgba(255,255,255,0.8); line-height:1.7; font-weight:400; text-align:center; border:3px solid $clrOrange; border-radius:50%; }
				&:hover { color:rgba(255,255,255,1);
					&::before { color:rgba(255,255,255,1); }
				}
			}
		}


	}
	.jquery-background-video-pauseplay {z-index:3;}

	@include respond(desktopS) {
		.blackout { background:rgba(23,23,23,0.7); }
		.promoBoxRight { position:absolute; top:0; right:0; left:50%; padding:1em 1em 1em 0; background:none;
			h1 { color:rgba(255,255,255,0.8); }
		}
	}
	@include respond(desktopM) {
		.promoBoxRight { top:1em; }
	}
	@include respond(desktopL) {
		.promoBoxRight {top:2em; }
	}
}