.advantagesImg { margin-bottom:2em; }
.moreBan { position:relative; cursor:pointer; padding-top:3em;
	img { opacity:0.9; transition:opacity ease-in-out $timeF; }
	.title { /*position:absolute; left:0; top:1em;*/ position:relative; text-align: left;
		strong { display:block; font-family:$fontRC; text-transform:uppercase; font-weight:400; font-size:150%; vertical-align:middle }
		.txt { display:block; margin:0 1em 1em 0; background-color:rgba(255,255,255,0.6); transition:background-color ease-in-out $timeF; }
	}

	@include respond(phoneL){ padding-top:0; }
	&:hover {
		img { opacity:1; }
		.title {
			.txt { background:rgba(255,255,255,0.8); }
		}
	}
}
.big-moreBan { margin-bottom:2em;
	@include respond(tabletS) {
		.title { width:50%;
			.txt { padding-right:1em;	}
		}
	}
}
.advantagesTxt {}
.cardTxt {
	h3 { margin-bottom:0.25em; display:flex; flex-flow:row nowrap; justify-content:flex-start; align-items:center;
		i { flex:0 0 2em; display:inline-block; vertical-align:top; width:2em; height:2em; margin-right:0.5em; border:0.25em solid $clrOrange; border-radius:50%; text-align:center; opacity:0.8; background:#fff;
			&::after { content:"+"; font:normal 400 1.95em/0.77em $fontR;  }
		}
	}

	p { margin-bottom:0; margin-top:0.5em; }
}