@mixin respond($media) {
  @if $media == less-phoneL {
    @media only screen and (max-width:calc($phoneL - 1)) { @content; }
  } @else if $media == less-tabletS {
    @media only screen and (max-width:calc($tabletS - 1)) { @content; }
  } @else if $media == less-tabletL {
    @media only screen and (max-width:calc($tabletL - 1)) { @content; }
  } @else if $media == less-desktopS {
    @media only screen and (max-width:calc($desktopS - 1)) { @content; }
  } @else if $media == phoneL {
    @media only screen and (min-width:$phoneL) { @content; }
  } @else if $media == tabletS {
    @media only screen and (min-width:$tabletS) { @content; }
  } @else if $media == tabletL {
    @media only screen and (min-width:$tabletL) { @content; }
  } @else if $media == desktopS {
    @media only screen and (min-width:$desktopS) { @content; }
  } @else if $media == desktopM {
    @media only screen and (min-width:$desktopM) { @content; }
  } @else if $media == desktopL {
    @media only screen and (min-width:$desktopL) { @content; }
  } @else if $media == phoneL-tabletS {
    @media only screen and (min-width:$phoneL) and (max-width:calc($tabletS - 1)) { @content; }
  } @else if $media == tabletS-tabletL {
    @media only screen and (min-width:$tabletS) and (max-width:calc($tabletL - 1)) { @content; }
  }@else if $media == tabletS-desktopS {
    @media only screen and (min-width:$tabletS) and (max-width:calc($desktopS - 1)) { @content; }
  }@else if $media == tabletL-desktopS {
    @media only screen and (min-width:$tabletL) and (max-width:calc($desktopS - 1)) { @content; }
  }@else if $media == tabletL-desktopM {
    @media only screen and (min-width:$tabletL) and (max-width:calc($desktopM - 1)) { @content; }
  }@else if $media == tabletL-desktopL {
    @media only screen and (min-width:$tabletL) and (max-width:calc($desktopL - 1)) { @content; }
  }@else if $media == desktopS-desktopM {
    @media only screen and (min-width:$desktopS) and (max-width:calc($desktopM - 1)) { @content; }
  }@else if $media == desktopS-desktopL {
    @media only screen and (min-width:$desktopS) and (max-width:calc($desktopL - 1)) { @content; }
  }@else if $media == desktopM-desktopL {
    @media only screen and (min-width:$desktopM) and (max-width:calc($desktopL - 1)) { @content; }
  }
}
/*
@include respond() { }
*/

.shDS-inline {display:none;
  @include respond(desktopS) { display:inline; }
}
.shDS-inlineBlock {display:none;
  @include respond(desktopS) { display:inline-block; }
}
.shDS-block {display:none;
  @include respond(desktopS) { display:block; }
}