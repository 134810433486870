@function strip-unit($number) {
	@if type-of($number) == 'number' and not unitless($number) {
		@return $number / ($number * 0 + 1);
	}
	@return $number;
}

@function calcFluidFontSize($f-min, $f-max, $w-min, $w-max, $units: px) {
	$f-min: strip-unit($f-min);
	$f-max: strip-unit($f-max);
	$w-min: strip-unit($w-min);
	$w-max: strip-unit($w-max);
	$k: ($f-max - $f-min)/($w-max - $w-min);
	$b: $f-min - $k * $w-min;
	$b: $b + $units;
	@return calc( #{$k} * 100vw + #{$b} );
}

.actionBox { display: block; position:relative; margin-bottom:2em;
	&:hover .btn {  background:rgba(0,0,0,0.5); color:#fff;  border-color:$clrOrange; }
}
a.actionBox {cursor:pointer;}

.actionH { position:absolute; right:3%; top:7%; @include clearfix(); color:#fff; font-size:calcFluidFontSize(8, 18, 320, 1920);
	h2 { text-transform:uppercase; margin:0; padding:0; display:inline-block; font:600 calcFluidFontSize(12, 48, 320, 1920)/1.3 $fontMA; color:$clrOrange; border-top:1px solid #fff; }
	@include respond(phoneL) { right:7%; top:13.3%; }
	@include respond(tabletS) {
		h2 { border-top:2px solid #fff; }
	}
}
.actionTW { font-weight:300; font-size:calcFluidFontSize(7, 23, 320, 1920); color:#fff; white-space:nowrap;}
.actionStar { display:none;
	@include respond(tabletS) { display:inline; }
}
.actionOldPrice { position:relative; float:left; font:500 calcFluidFontSize(8, 27, 320, 1920)/1 $fontMA; margin:1em 1em 1em 0;
	&::after { content:''; position:absolute; left:0; bottom:0; width:100%; height:2px; background:#fff; transform:rotate(14deg);transform-origin:100% 100%;  }
}
.actionPrice {float:right; font:600 calcFluidFontSize(19, 81, 320, 1920)/1.1 $fontMA; letter-spacing: -0.03em; color:$clrOrange; border-bottom:1px solid #fff;
	@include respond(tabletS) { border-bottom:2px solid #fff;	}
}

.actionTxt { position:absolute; right:3%; top:62%;
	p { margin-bottom:1em;}
	@include respond(phoneL) { right:7%; }

	ul{ @include listR(); text-align: right;
		li { margin-bottom: 0.5rem; }
		a { color:#fff; text-decoration: none; 
			&:hover { color:$clrOrange; text-decoration: underline; }
		}
	}

}
.actionTD { font-weight:500; font-size:calcFluidFontSize(7, 23, 320, 1920); color:$clrBlack; white-space:nowrap; }

.actionBtn { display:none; text-align:right;
	@include respond(tabletS) {
		display:block;
	}
}
.actionFootnote { display:none; position:absolute; right:7%; bottom:3%; width:93%; font-size:0.85em; font-family:$fontRC; text-align:right;
	@include respond(tabletS) {
		display:block;
	}
}
.actionBan { position:relative;
	@include respond(phoneL) {
		.actionTxt {right:4%; }
		.actionH {top:11%; right:4%; }
	}
}

.inStock { position:absolute; left:-5px; top:7%; font:400 1em/2em $fontRC; padding:0 1.5em; border-radius: 2px 1em 1em 0;  background:$clrOrange; color:#fff; text-transform:uppercase; border-top:2px solid $clrOrangeL;
	&::after { content:''; position:absolute; z-index:-1; left:0; bottom:-4px; width:0; height:0; border-left:5px solid transparent; border-right:5px solid transparent; border-bottom:5px solid $clrOrangeD; transform: rotate(45deg)}
}
