.footer { position:relative; background:$clrBlack; padding:2em 0;
	.footerCols {
		.footerLeftCol, .footerRightCol { margin-bottom:0.5em; }
		.footerLeftCol {}
		.footerRightCol {}
	}
	.footerTxt {
		span.copy, .linkFooter { display:block; margin-bottom:0.25em; color:$clrGray; font-size:0.889em; line-height:1.33em; }
		.linkFooter {font-size:0.78em; }
		a { color:$clrGray; 
			&:hover { color:$clrOrange; }
		}
		
	}
	.copy { display:block; white-space:nowrap; margin-bottom:0.25em; }
	.contact { position:relative; top:-2px;
		.tel { display:inline-block; vertical-align:top; font-weight:700; font-size:17px; line-height:24px; text-decoration:none; color:#fff; white-space:nowrap;
			.icon { display:inline-block; vertical-align:top; width:24px; height:24px; margin:0 0.33em 0 0; background-color:red; background-position:0 0; }
			.pre { color:$clrGray; }
		}
		.dotted { display:inline-block; vertical-align:top; margin-left:1em; font:400 11px/24px $fontRC; text-transform:uppercase; color:$clrGray; white-space:nowrap;
			&:hover, &:active { color:#fff; }
		}
	}
	@include respond(tabletS) {
		.footerCols { display:flex; flex-flow:row wrap;  align-items:center;
			.footerLeftCol {  flex-grow:1; }
		}
		.contact { text-align:right;
			.tel { display:block; }
			.dotted { line-height:1.5; }
		}
	}
	@include respond(desktopS) {
		.contact {
			.tel { font-size:24px; line-height:1; }
			.dotted { margin:0; font-size:13px; letter-spacing:1px; }
		}
	}
}

.infoCookies { display:none; position:fixed; z-index:10; right:5px; bottom:5px; margin-left:5px; background:rgba(240,240,240,0.9); border:2px solid $clrOrange; padding:0.75em; color:$clrTxt; text-align:center;
	.no-hiddenscroll .noScroll & { right:22px; }
	h4 { text-align:center; }
	a {}
	p { font-size:0.889em; }
	@include respond(tabletS) { right:10px; bottom:10px; margin-left:10px; padding:30px;
		.no-hiddenscroll .noScroll & { right:27px; }
	}
}