@mixin centerA(){ position:absolute; top:50%; left:50%; transform:translate(-50%,-50%); }

@mixin listR() { margin:0; padding:0; list-style:none;
	> li { margin:0; padding:0; list-style:none; background:none;
		&::before, &::after { content:none; }
	}
}
@mixin clearfix() {
  &:after { content:''; display:table; clear:both; }
}

@mixin listH() { @include listR(); display:flex; flex-flow:row wrap; flex:auto; }

@mixin hz() { display:flex; flex-flow:row nowrap; justify-content:space-between; align-items:center; }

@mixin imgResp(){ display:block; min-width:100%; max-width:100%; height:auto; }

@mixin textShadow(){text-shadow: 0 1px 1px rgba(0,0,0,.8);}

