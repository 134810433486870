.btn { position:relative; display:inline-block; vertical-align:top; height:32px; padding:0 1em; text-decoration:none; text-align:center; font:400 15px/28px $fontRC; letter-spacing:1px; text-transform:uppercase; cursor:pointer; white-space:nowrap; color:$clrBlack; background-color:rgba(0,0,0,0.1); border:2px solid $clrGray; transition:background-color ease-in-out $timeF, border-color ease-in-out $timeF, color ease-in-out $timeF;
	&.btnSmall { height:24px; font-size:13px; line-height:20px; }
	@include respond(desktopS) { height:48px; padding:0 1.5em; font-size:19px; line-height:44px;
		&.btnSmall { height:32px; font-size:15px; line-height:28px; }
	}
	&:hover, &:active,
	a:hover &, .triggerAF:hover & { text-decoration:none; color:$clrBlack; border-color:$clrOrange; background:rgba(255,255,255,0.1); }
	a:hover .layerH &, .layerH &:hover { color:#fff; background-color: $clrBlackA; }


	&::-moz-focus-inner { border:0; }
}
.btnDH { color:#fff; text-shadow:0 1px 1px rgba(0,0,0,0.8);
	&:hover, &:active {  background:rgba(23,23,23,0.5); color:#fff; }
}
.formCRM { margin:-15px 0; }

.rowBtn { text-align:right; margin-bottom:1em;}

