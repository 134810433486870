ul.listContact { @include listR(); margin-bottom:1em; font-size:90%; line-height:1.5;
	li { margin-bottom:0.5em; display:flex; flex-flow:row nowrap; flex:auto; 
		&.marginUp { margin-top: -0.5em; }
	}
	span.label { flex: 0 0 auto; margin-right:0.5em; font-family:$fontRC; color:$clrGray; }
	strong { flex: 1 1 auto; color:$clrTxt; font-weight:400;
		&.big { font-weight:400; font-size:110%; }
	}
	.small { display:block; font-size:80%; color:$clrTxt; }
	a { text-decoration:none; color:$clrTxt;
		&:hover { text-decoration:underline; }
	}
	@include respond(tabletS) { }
}

.contactsBox { padding:0.5rem 1rem; margin-bottom: 1rem; border-left: 0.2rem solid $clrBrdr;
	h5 { font-weight:400; font-size:0.95em; }
	ul.listContact { margin-bottom:0;
		li {
			&:last-child { margin-bottom: 0; }
		}
	}
	.tel { text-decoration:none; color:$clrTxt; font-weight:400; font-size:110%;
		&:hover { text-decoration:underline; }
	}
}

.contactsIndent {display: block; clear: both; height: 0.5rem ; width: 100%;}
