/**
 * Set default positioning as a fallback for if the plugin fails
 */
.jquery-background-video-wrapper {
	position: relative;
	overflow: hidden;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}
.jquery-background-video {
	position: absolute;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	top: 50%;
	left: 50%;
	object-fit: cover;
	transform: translate(-50%,-50%);
}
/**
 * Fade in videos
 * Note the .js class - so non js users still
 * see the video
 */
.js .jquery-background-video {
	opacity: 0;
	transition: opacity 300ms linear;
}
.js .jquery-background-video.is-visible {
	opacity: 1;
}

/**
 * Pause/play button
 */
.jquery-background-video-pauseplay {
	position: absolute;
	background: transparent !important;
	border: none !important;
	box-shadow: none !important;
	width: 20px;
	height: 20px;
	top: 15px;
	right: 15px;
	padding: 0 !important;
	cursor: pointer;
	outline: none !important;
}
.jquery-background-video-pauseplay span {
	display: none;
}
.jquery-background-video-pauseplay:after,
.jquery-background-video-pauseplay:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	transition: all .3s ease;
}
.jquery-background-video-pauseplay.play:before {
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	border-left: 15px solid #FFF;
}
.jquery-background-video-pauseplay.pause:before,
.jquery-background-video-pauseplay.pause:after {
	border-top: 10px solid #FFF;
	border-bottom: 10px solid #FFF;
	border-left: 5px solid #FFF;
}
.jquery-background-video-pauseplay.pause:after {
	left: 10px;
}