@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i|Roboto:300,400,400i,700,900&subset=cyrillic,cyrillic-ext,latin-ext");
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i|Roboto:300,300i,400,400i,500,500i,700,700i,900,900i|Montserrat+Alternates:300,400,500,600,700,800,900&display=swap&subset=cyrillic,cyrillic-ext");
.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
}

.icon-arrow {
  font-size: 0.8rem;
  width: 1.75em;
}

.icon-bodor_logo {
  font-size: 28.34rem;
  width: 2.5903316867em;
}

/*
@include respond() { }
*/
.shDS-inline {
  display: none;
}
@media only screen and (min-width: 1220px) {
  .shDS-inline {
    display: inline;
  }
}

.shDS-inlineBlock {
  display: none;
}
@media only screen and (min-width: 1220px) {
  .shDS-inlineBlock {
    display: inline-block;
  }
}

.shDS-block {
  display: none;
}
@media only screen and (min-width: 1220px) {
  .shDS-block {
    display: block;
  }
}

.w_1 {
  width: 8.3333333333%;
}

.w_2 {
  width: 16.6666666667%;
}

.w_3 {
  width: 25%;
}

.w_4 {
  width: 33.3333333333%;
}

.w_5 {
  width: 41.6666666667%;
}

.w_6 {
  width: 50%;
}

.w_7 {
  width: 58.3333333333%;
}

.w_8 {
  width: 66.6666666667%;
}

.w_9 {
  width: 75%;
}

.w_10 {
  width: 83.3333333333%;
}

.w_11 {
  width: 91.6666666667%;
}

.w_12 {
  width: 100%;
}

@media only screen and (min-width: 480px) {
  .wPL_1 {
    width: 8.3333333333%;
  }

  .wPL_2 {
    width: 16.6666666667%;
  }

  .wPL_3 {
    width: 25%;
  }

  .wPL_4 {
    width: 33.3333333333%;
  }

  .wPL_5 {
    width: 41.6666666667%;
  }

  .wPL_6 {
    width: 50%;
  }

  .wPL_7 {
    width: 58.3333333333%;
  }

  .wPL_8 {
    width: 66.6666666667%;
  }

  .wPL_9 {
    width: 75%;
  }

  .wPL_10 {
    width: 83.3333333333%;
  }

  .wPL_11 {
    width: 91.6666666667%;
  }

  .wPL_12 {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) {
  .wTS_1 {
    width: 8.3333333333%;
  }

  .wTS_2 {
    width: 16.6666666667%;
  }

  .wTS_3 {
    width: 25%;
  }

  .wTS_4 {
    width: 33.3333333333%;
  }

  .wTS_5 {
    width: 41.6666666667%;
  }

  .wTS_6 {
    width: 50%;
  }

  .wTS_7 {
    width: 58.3333333333%;
  }

  .wTS_8 {
    width: 66.6666666667%;
  }

  .wTS_9 {
    width: 75%;
  }

  .wTS_10 {
    width: 83.3333333333%;
  }

  .wTS_11 {
    width: 91.6666666667%;
  }

  .wTS_12 {
    width: 100%;
  }
}
@media only screen and (min-width: 1024px) {
  .wTL_1 {
    width: 8.3333333333%;
  }

  .wTL_2 {
    width: 16.6666666667%;
  }

  .wTL_3 {
    width: 25%;
  }

  .wTL_4 {
    width: 33.3333333333%;
  }

  .wTL_5 {
    width: 41.6666666667%;
  }

  .wTL_6 {
    width: 50%;
  }

  .wTL_7 {
    width: 58.3333333333%;
  }

  .wTL_8 {
    width: 66.6666666667%;
  }

  .wTL_9 {
    width: 75%;
  }

  .wTL_10 {
    width: 83.3333333333%;
  }

  .wTL_11 {
    width: 91.6666666667%;
  }

  .wTL_12 {
    width: 100%;
  }
}
@media only screen and (min-width: 1220px) {
  .wDS_1 {
    width: 8.3333333333%;
  }

  .wDS_2 {
    width: 16.6666666667%;
  }

  .wDS_3 {
    width: 25%;
  }

  .wDS_4 {
    width: 33.3333333333%;
  }

  .wDS_5 {
    width: 41.6666666667%;
  }

  .wDS_6 {
    width: 50%;
  }

  .wDS_7 {
    width: 58.3333333333%;
  }

  .wDS_8 {
    width: 66.6666666667%;
  }

  .wDS_9 {
    width: 75%;
  }

  .wDS_10 {
    width: 83.3333333333%;
  }

  .wDS_11 {
    width: 91.6666666667%;
  }

  .wDS_12 {
    width: 100%;
  }
}
.grid {
  margin: 0;
  padding: 0;
  list-style: none;
  clear: both;
  margin: 0 -10px;
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
}
.grid > li {
  margin: 0;
  padding: 0;
  list-style: none;
  background: none;
}
.grid > li::before, .grid > li::after {
  content: none;
}
.grid > .boxGrid {
  flex: 0 1 100%;
  position: relative;
  margin-bottom: 20px;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  background-clip: padding-box;
}
@media only screen and (min-width: 768px) {
  .grid {
    margin: 0 -20px;
  }
  .grid .boxGrid {
    margin-bottom: 40px;
    border-right: 20px solid transparent;
    border-left: 20px solid transparent;
  }
}

.grid-4 > .boxGrid {
  flex: 0 1 50%;
}
@media only screen and (min-width: 768px) {
  .grid-4 {
    margin: 0 -10px;
  }
  .grid-4 > .boxGrid {
    flex: 0 1 25%;
    margin-bottom: 20px;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
  }
}

.grid-3 {
  margin: 0 -10px;
}
.grid-3 > .boxGrid {
  flex: 0 1 100%;
}
@media only screen and (min-width: 768px) {
  .grid-3 > .boxGrid {
    flex: 0 1 50%;
    margin-bottom: 20px;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
  }
}
@media only screen and (min-width: 1220px) {
  .grid-3 > .boxGrid {
    flex: 0 1 33.33%;
  }
}

.grid-2 > .boxGrid {
  flex: 0 1 100%;
}
@media only screen and (min-width: 1024px) {
  .grid-2 > .boxGrid {
    flex: 0 1 50%;
  }
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: none;
}

body {
  font: 400 14px/1.5 Roboto, sans-serif;
  color: #444;
}
@media only screen and (min-width: 768px) {
  body {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1220px) {
  body {
    font-size: 18px;
  }
}

h1, h2, h3 {
  clear: both;
}

h1 {
  font: 400 200%/1.2 Roboto, sans-serif;
  text-transform: uppercase;
  margin-bottom: 0.5em;
}

h1.seriesH {
  font: 400 200%/1.2 Roboto Condensed, sans-serif;
  text-align: center;
  margin: 1.5em 0 0;
}
h1.seriesH strong {
  display: block;
  font-weight: 400;
  color: #e94f13;
}

h1.articleH {
  margin: 1.5em 0 1em 0;
  text-transform: none;
  text-align: left;
}

h1.defaultH {
  font: 400 200%/1.2 Roboto Condensed, sans-serif;
  text-align: center;
  margin: 1.5em 0;
}

h2 {
  font: 700 170%/1.2 Roboto, sans-serif;
  text-transform: uppercase;
  margin-bottom: 1.5em;
  padding-top: 1em;
}

h2.styleH2, .styleH2 {
  font: 400 170%/1.1 Roboto, sans-serif;
  text-transform: uppercase;
  margin-bottom: 1.5em;
  padding-top: 1em;
  text-align: left;
  color: #444;
}
h2.styleH2:first-letter, .styleH2:first-letter {
  color: #e94f13;
  font-size: 1.05em;
}
h2.styleH2::after, .styleH2::after {
  content: "";
  display: block;
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, #e94f13 0%, rgba(156, 157, 157, 0.2) 100%);
}

h2.bb {
  font: 400 170%/1.2 Roboto Condensed, sans-serif;
  border-bottom: 3px solid #ebebeb;
}
h2.bb strong {
  font-weight: 400;
  color: #e94f13;
}

h3 {
  font: 400 130%/1.2 Roboto Condensed, sans-serif;
  text-transform: uppercase;
  margin-bottom: 0.5em;
}

h3.large {
  font: 400 150%/1.2 Roboto Condensed, sans-serif;
  text-transform: uppercase;
  padding-top: 1em;
  margin-bottom: 1em;
}

h3.headerItem {
  display: inline-block;
  position: relative;
  font: 400 140%/1 Roboto Condensed, sans-serif;
  text-transform: uppercase;
  margin: 0 0 -1em;
  padding: 0.5em 1.5em 0.5em 1.5em;
  color: #fff;
  background: #eb602a;
  opacity: 0.9;
  border-radius: 2px 1em 1em 2px;
}
h3.headerItem::before {
  content: "";
  position: absolute;
  left: 0.5em;
  top: 50%;
  width: 0.6em;
  height: 0.6em;
  margin-top: -0.3em;
  border: 3px solid #fff;
  border-radius: 50%;
}

h4 {
  font: 400 111%/1.2 Roboto Condensed, sans-serif;
  text-transform: uppercase;
  margin-bottom: 0.5em;
}

h5 {
  font: 700 100%/1.2 Roboto Condensed, sans-serif;
  text-transform: uppercase;
  margin-bottom: 0.5em;
}

p {
  margin-bottom: 1.5em;
}

.txtThin {
  font-weight: 300;
}

dl {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 0.5em;
  flex: auto;
  clear: both;
}

dl dt {
  flex: 0 0 auto;
  margin-right: 1em;
}

dl dd {
  flex: 1 1 auto;
  font-weight: 700;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin: 0 0 2em 0;
}
ul > li {
  margin: 0;
  padding: 0;
  list-style: none;
  background: none;
}
ul > li::before, ul > li::after {
  content: none;
}
ul li {
  display: block;
  position: relative;
  margin-bottom: 0.75em;
  padding-left: 1.5em;
}
ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.33em;
  display: block;
  width: 0.75em;
  height: 0.75em;
  border: 2px solid #e94f13;
  border-radius: 50%;
}

ol {
  margin: 0;
  padding: 0;
  list-style: none;
  counter-reset: list;
  padding-left: 1.5em;
  margin-bottom: 2em;
}
ol > li {
  margin: 0;
  padding: 0;
  list-style: none;
  background: none;
}
ol > li::before, ol > li::after {
  content: none;
}
ol li {
  position: relative;
  margin: 0 0 0.125em 0;
  list-style-type: none;
  line-height: 1.2;
  padding: 0.4em 0;
  display: inline-block;
  width: 100%;
  vertical-align: top;
}
ol > li {
  padding-left: 0.5em;
}
ol > li:before {
  counter-increment: list;
  content: counter(list) ".";
  position: absolute;
  top: 0;
  right: 100%;
  width: 1.5em;
  height: 2em;
  color: #e94f13;
  line-height: 2;
  font-weight: 700;
  text-align: center;
}
ol > li ol {
  counter-reset: list2;
  margin: 0;
  padding: 0.5em 0 0 2em;
}
ol > li ol li:before {
  counter-increment: list2;
  content: counter(list) "." counter(list2) ".";
  position: absolute;
  top: 0;
  right: 100%;
  width: auto;
}
ol > li ol li ol {
  counter-reset: list3;
  margin: 0;
  padding: 0.5em 0 0 2.5em;
}
ol > li ol li ol li:before {
  counter-increment: list3;
  content: counter(list) "." counter(list2) "." counter(list3) ".";
  position: absolute;
  top: 0;
  right: 100%;
}
ol > li ol li ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin: 0;
  padding-top: 0.75em;
}
ol > li ol li ul > li {
  margin: 0;
  padding: 0;
  list-style: none;
  background: none;
}
ol > li ol li ul > li::before, ol > li ol li ul > li::after {
  content: none;
}
ol > li ol li ul li {
  display: block;
  position: relative;
  margin-bottom: 0.75em;
  padding-left: 1.5em;
}
ol > li ol li ul li::before {
  counter-increment: none;
  content: "";
  position: absolute;
  left: 0;
  top: 0.25em;
  display: block;
  width: 0.75em;
  height: 0.75em;
  border: 2px solid #e94f13;
  border-radius: 50%;
}

ul.noMarkers {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 2em;
}
ul.noMarkers > li {
  margin: 0;
  padding: 0;
  list-style: none;
  background: none;
}
ul.noMarkers > li::before, ul.noMarkers > li::after {
  content: none;
}
ul.noMarkers li {
  margin-bottom: 0.25em;
}

p.prevList {
  margin-bottom: 0.25em;
}

a, .dotted, .linkType {
  color: #0a61bf;
  text-decoration: underline;
  cursor: pointer;
}
a:hover, .dotted:hover, .linkType:hover {
  color: #e94f13;
}
a:focus, .dotted:focus, .linkType:focus {
  outline: none;
}
a img, .dotted img, .linkType img {
  border: none;
}

.dotted .bd {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  border-bottom: 1px dotted;
}

.floatL-img {
  text-align: center;
  margin: 0 0 2em 0;
}
.floatL-img:after {
  content: "";
  display: table;
  clear: both;
}
@media only screen and (min-width: 768px) {
  .floatL-img {
    float: left;
    clear: left;
    margin: 0 2em 2em 0;
  }
}

.floatR-img {
  text-align: center;
  margin: 0 0 2em 0;
}
.floatR-img:after {
  content: "";
  display: table;
  clear: both;
}
@media only screen and (min-width: 768px) {
  .floatR-img {
    float: right;
    clear: right;
    margin: 0 0 2em 2em;
  }
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.boxCenter {
  margin-left: auto;
  margin-right: auto;
}

.alignL {
  text-align: left;
}

.alignR {
  text-align: right;
}

.alignC {
  text-align: center;
}

.columnTwo {
  column-count: 2;
  column-gap: 2em;
  margin-bottom: 2em;
}

.nowrap {
  white-space: nowrap;
}

.clrOrange {
  color: #e94f13;
}

.fontThin {
  font-weight: 300;
}

.fontItalic {
  font-style: italic;
}

.fontBig {
  font-size: 110%;
}

.fontSmall {
  font-size: 85%;
}

.tableWrap {
  clear: both;
  display: block;
  margin: 0 0 2em 0;
  padding: 1em 0;
  font-size: 90%;
  overflow-x: auto;
}
.tableWrap table {
  width: 100%;
  border-collapse: collapse;
}
.tableWrap table th, .tableWrap table td {
  padding: 0.5em 1em;
  border-right: 3px solid #fff;
  border-left: 3px solid #fff;
}
.tableWrap table thead th {
  background: #444;
  color: #fff;
  border-top: none;
  border-bottom: 3px solid #fff;
}
.tableWrap table thead th {
  text-align: center;
}
.tableWrap table thead th:first-child {
  text-align: left;
  text-transform: uppercase;
  font-weight: 400;
}
.tableWrap table tbody th, .tableWrap table tbody td {
  border-bottom: 1px solid #ebebeb;
}
.tableWrap table tbody th {
  text-align: left;
}
.tableWrap table tbody th em {
  color: #e94f13;
}
.tableWrap table tbody td {
  text-align: center;
  border-bottom: 1px solid #ebebeb;
}
.tableWrap table tbody td:first-child {
  text-align: left;
}
.tableWrap table tbody tr.alignC td:first-child {
  text-align: center;
}
.tableWrap table tbody tr:hover th:not([rowspan]), .tableWrap table tbody tr:hover td:not([rowspan]) {
  background: #f5f5f5;
}
.tableWrap table.alignC thead th {
  text-align: center;
  text-transform: none;
  font-weight: 400;
}
.tableWrap table.alignC thead th:first-child {
  text-align: center;
  text-transform: none;
  font-weight: 400;
}
.tableWrap table.alignC tbody td:first-child {
  text-align: center;
}

figure figcaption {
  font-size: 0.889rem;
  line-height: 1.3;
  color: #6a6b6b;
  font-style: italic;
  text-align: right;
}

figure.imgTxt {
  margin-bottom: 2em;
}
figure.imgTxt figcaption {
  font-size: 0.889em;
  color: #9c9d9d;
  font-style: italic;
  text-align: right;
}

.boxBG {
  margin-bottom: 2em;
  padding: 1em 1em 0.75em;
  background: rgba(245, 245, 245, 0.5);
}
.boxBG p, .boxBG ul {
  margin-bottom: 0.5em;
}

blockquote {
  padding: 1em;
  margin-bottom: 2em;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  font-style: italic;
  font-size: 1.11em;
}

.indent {
  display: block;
  height: 0;
  padding-top: 2rem;
}

/**
 * Set default positioning as a fallback for if the plugin fails
 */
.jquery-background-video-wrapper {
  position: relative;
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.jquery-background-video {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  object-fit: cover;
  transform: translate(-50%, -50%);
}

/**
 * Fade in videos
 * Note the .js class - so non js users still
 * see the video
 */
.js .jquery-background-video {
  opacity: 0;
  transition: opacity 300ms linear;
}

.js .jquery-background-video.is-visible {
  opacity: 1;
}

/**
 * Pause/play button
 */
.jquery-background-video-pauseplay {
  position: absolute;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  width: 20px;
  height: 20px;
  top: 15px;
  right: 15px;
  padding: 0 !important;
  cursor: pointer;
  outline: none !important;
}

.jquery-background-video-pauseplay span {
  display: none;
}

.jquery-background-video-pauseplay:after,
.jquery-background-video-pauseplay:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.3s ease;
}

.jquery-background-video-pauseplay.play:before {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #FFF;
}

.jquery-background-video-pauseplay.pause:before,
.jquery-background-video-pauseplay.pause:after {
  border-top: 10px solid #FFF;
  border-bottom: 10px solid #FFF;
  border-left: 5px solid #FFF;
}

.jquery-background-video-pauseplay.pause:after {
  left: 10px;
}

.btn {
  position: relative;
  display: inline-block;
  vertical-align: top;
  height: 32px;
  padding: 0 1em;
  text-decoration: none;
  text-align: center;
  font: 400 15px/28px Roboto Condensed, sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  color: #171717;
  background-color: rgba(0, 0, 0, 0.1);
  border: 2px solid #9c9d9d;
  transition: background-color ease-in-out 300ms, border-color ease-in-out 300ms, color ease-in-out 300ms;
}
.btn.btnSmall {
  height: 24px;
  font-size: 13px;
  line-height: 20px;
}
@media only screen and (min-width: 1220px) {
  .btn {
    height: 48px;
    padding: 0 1.5em;
    font-size: 19px;
    line-height: 44px;
  }
  .btn.btnSmall {
    height: 32px;
    font-size: 15px;
    line-height: 28px;
  }
}
.btn:hover, .btn:active, a:hover .btn, .dotted:hover .btn, .triggerAF:hover .btn {
  text-decoration: none;
  color: #171717;
  border-color: #e94f13;
  background: rgba(255, 255, 255, 0.1);
}
a:hover .layerH .btn, .dotted:hover .layerH .btn, .layerH .btn:hover {
  color: #fff;
  background-color: rgba(23, 23, 23, 0.9);
}
.btn::-moz-focus-inner {
  border: 0;
}

.btnDH {
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.8);
}
.btnDH:hover, .btnDH:active {
  background: rgba(23, 23, 23, 0.5);
  color: #fff;
}

.formCRM {
  margin: -15px 0;
}

.rowBtn {
  text-align: right;
  margin-bottom: 1em;
}

*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

html {
  height: 100%;
  text-size-adjust: none;
}

body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
}

.noScroll {
  overflow: hidden;
}
.no-hiddenscroll .noScroll {
  padding-right: 17px;
}
.no-hiddenscroll .noScroll .fix {
  right: 17px;
}

.content {
  flex-grow: 1;
}

.container {
  position: relative;
  max-width: 1360px;
  min-width: 320px;
  margin: 0 auto;
  padding-right: 10px;
  padding-left: 10px;
}
.container:after {
  content: "";
  display: table;
  clear: both;
}
@media only screen and (min-width: 768px) {
  .container {
    padding-right: 20px;
    padding-left: 20px;
  }
}
.pInner .content .container {
  padding-bottom: 3em;
}

.section {
  padding-bottom: 3em;
  overflow: hidden;
}

.boxVideo {
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  overflow: hidden;
  margin-bottom: 2em;
  background-color: #9c9d9d;
}
.boxVideo iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.video01 {
  padding: 56.25% 0 0 0;
}

.hz {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.imgResp {
  display: block;
  min-width: 100%;
  max-width: 100%;
  height: auto;
}

.item-gallery {
  display: block;
  overflow: hidden;
  cursor: zoom-in;
}
.item-gallery img {
  transition: transform ease-in-out 300ms;
}
.item-gallery:hover img {
  transform: scale(1.1);
}

.loading-effect-3 {
  width: 100px;
  height: 100px;
  margin: 1em auto 0 auto;
}
.loading-effect-3 div {
  display: block;
  width: 100%;
  height: 100%;
  border: 2px solid #9c9d9d;
  border-radius: 50%;
}
.loading-effect-3 div > span {
  background: #e94f13;
  width: 10px;
  height: 10px;
  display: block;
  border-radius: 50%;
  position: relative;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
  transform-origin: 0 54px;
  animation: effect-3 2s infinite linear;
}

@keyframes effect-3 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.requestBlock {
  background: #f5f5f5;
  border-top: 1px solid #ebebeb;
}
.requestBlock .container, .articleFull .requestBlock .container {
  max-width: 960px;
}
.requestBlock h3.large {
  text-align: center;
}

ul.listFAQ {
  margin: 0;
  padding: 0;
  list-style: none;
  margin: 0 -10px;
}
ul.listFAQ > li {
  margin: 0;
  padding: 0;
  list-style: none;
  background: none;
}
ul.listFAQ > li::before, ul.listFAQ > li::after {
  content: none;
}
ul.listFAQ li {
  margin: 0 0 1em 0;
  padding: 0;
  list-style: none;
  background: none;
}
ul.listFAQ .qFAQ, ul.listFAQ .aFAQ {
  position: relative;
}
ul.listFAQ .qFAQ {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
ul.listFAQ .iconQ {
  display: block;
  position: relative;
  top: -1px;
  flex: 0 0 auto;
  width: 2.6em;
  height: 2.6em;
  text-align: center;
  background: white;
  border: 0.3em solid #eb602a;
  border-radius: 50%;
  margin-right: 1em;
}
ul.listFAQ .iconQ::before {
  content: "?";
  font-weight: 700;
  font-style: normal;
  font-size: 2em;
  line-height: 1.05;
}
ul.listFAQ .txtQ {
  display: block;
  flex: 1 1 auto;
  text-transform: uppercase;
}
ul.listFAQ .aFAQ {
  background: #fff;
  padding: 0 0 0 1.2em;
}
ul.listFAQ .txtA {
  display: block;
  border-left: 0.2em solid #e4e9f0;
  padding: 0.5em 0 0.5em 1em;
  font-weight: 300;
}
@media only screen and (min-width: 768px) {
  ul.listFAQ {
    margin: 0 -20px;
  }
}

.b24-form-content, .b24-form-padding-side {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.articleFull {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  transform: scale(0);
  opacity: 0;
  transition: transform 400ms ease-in-out, opacity 400ms ease-in-out;
  background: #fff;
  text-align: center;
  white-space: nowrap;
}
.articleFull .closeAF {
  position: absolute;
  z-index: 3;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
  padding: 10px;
  cursor: pointer;
}
.articleFull .closeAF i {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
}
.articleFull .closeAF i::before, .articleFull .closeAF i::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  width: 2px;
  height: 100%;
  background: #9c9d9d;
}
.articleFull .closeAF i:before {
  transform: skew(-45deg, 45deg);
}
.articleFull .closeAF i:after {
  transform: skew(45deg, -45deg);
}
.articleFull .closeAF:hover i::before, .articleFull .closeAF:hover i::after {
  background: #e94f13;
}
.articleFull:before {
  content: "";
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  margin-left: -0.4em;
}
.articleFull.active {
  width: 100%;
  height: 100%;
  transform: scale(1);
  opacity: 1;
  overflow: scroll;
  overflow-x: auto;
}
.articleFull .container {
  display: inline-block;
  width: 100%;
  padding-top: 2em;
  padding-bottom: 2em;
  max-width: 1200px;
  vertical-align: middle;
  white-space: normal;
  text-align: left;
}
.articleFull h3:not(.headerItem) {
  padding-top: 1em;
}
.articleFull h4.txtR {
  margin-right: 25px;
}

.navPanelWrap {
  position: relative;
  height: 47px;
  z-index: 4;
  background: #171717;
}
@media only screen and (min-width: 1220px) {
  .navPanelWrap {
    height: 83px;
  }
}

.navPanel {
  height: 47px;
  border-bottom: 3px solid #e94f13;
  background-color: rgba(23, 23, 23, 0.9);
  transition: top 300ms ease-in-out, background-color 200ms ease-in-out;
}
.pIndex .navPanel {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
.no-hiddenscroll .noScroll .navPanel {
  right: 17px;
}
.navPanel.hide {
  top: -90px;
}
.navPanel .container {
  background: #171717;
}
.navPanel .logo {
  display: block;
  float: left;
  width: 90px;
  margin: 5px 0 0 40px;
}
.navPanel .logo img {
  display: block;
  max-width: 100%;
  height: auto;
}
.navPanel .nav {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: -500px;
  background: #171717;
  transition: top 300ms ease-in-out;
  text-transform: uppercase;
}
.navPanel .nav.active {
  top: 47px;
}
.navPanel ul.menu {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
  border-bottom: 3px solid #e94f13;
}
.navPanel ul.menu > li {
  margin: 0;
  padding: 0;
  list-style: none;
  background: none;
}
.navPanel ul.menu > li::before, .navPanel ul.menu > li::after {
  content: none;
}
.navPanel .navItem {
  display: block;
  padding: 0 22px;
  font-size: 15px;
  font-weight: 400;
  line-height: 44px;
  color: #9c9d9d;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
}
.navPanel .navItem:hover, .navPanel .navItem:active {
  background: #994f13;
  color: #fff;
}
.navPanel .hamburger {
  position: relative;
  float: left;
  width: 44px;
  height: 44px;
  cursor: pointer;
}
.navPanel .hamburger .line {
  position: absolute;
  left: 10px;
  background: #9c9d9d;
  width: 24px;
  height: 4px;
}
.navPanel .hamburger .l-01 {
  top: 12px;
}
.navPanel .hamburger .l-02 {
  top: 20px;
}
.navPanel .hamburger .l-03 {
  top: 28px;
}
.navPanel .hamburger:hover .line, .navPanel .hamburger:active .line {
  background: #fff;
}
.navPanel .contact {
  position: absolute;
  top: 0;
  right: 10px;
  text-align: center;
}
.navPanel .contact .tel {
  display: inline-block;
  vertical-align: top;
  font-weight: 700;
  font-size: 17px;
  line-height: 44px;
  text-decoration: none;
  color: #fff;
  white-space: nowrap;
}
.navPanel .contact .tel .pre {
  color: #9c9d9d;
}
.navPanel .contact .dotted {
  display: none;
  vertical-align: top;
  margin-left: 1em;
  font: 400 11px/44px Roboto Condensed, sans-serif;
  text-transform: uppercase;
  color: #9c9d9d;
  white-space: nowrap;
}
.navPanel .contact .dotted:hover, .navPanel .contact .dotted:active {
  color: #fff;
}
.navPanel .triggerSM .navItem {
  display: none;
}
.navPanel ul.subMenu {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
}
.navPanel ul.subMenu > li {
  margin: 0;
  padding: 0;
  list-style: none;
  background: none;
}
.navPanel ul.subMenu > li::before, .navPanel ul.subMenu > li::after {
  content: none;
}
.navPanel ul.subMenu .navAnc {
  display: block;
  padding: 10px 22px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.25;
  color: #9c9d9d;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
}
.navPanel ul.subMenu .navAnc:hover, .navPanel ul.subMenu .navAnc:active {
  background: #994f13;
  color: #fff;
}
@media only screen and (min-width: 480px) {
  .navPanel .logo {
    display: block;
    position: relative;
    float: left;
    width: 90px;
    margin: 5px 0 0 40px;
  }
  .navPanel .contact .dotted {
    display: inline-block;
  }
}
@media only screen and (min-width: 768px) {
  .navPanel .navItem {
    display: block;
    padding: 0 32px;
  }
  .navPanel .contact {
    right: 20px;
  }
}
@media only screen and (min-width: 1220px) {
  .navPanel {
    height: 83px;
  }
  .navPanel.fix {
    background: rgba(0, 30, 61, 0.8);
  }
  .navPanel .container {
    background: none;
  }
  .navPanel .logo {
    width: 170px;
    margin: 8px 0.5em 0 0;
  }
  .navPanel .hamburger {
    display: none;
  }
  .navPanel .nav {
    display: block;
    position: relative;
    top: 18px;
    z-index: auto;
    background: transparent;
    transition: none;
    margin: 0 180px 0 170px;
  }
  .navPanel .navItem {
    padding: 0 0.5em;
    border-bottom: none;
    color: #9c9d9d;
    font-size: 17px;
  }
  .navPanel .navItem:hover, .navPanel .navItem:active {
    background: none;
    color: #fff;
  }
  .navPanel ul.menu {
    border: none;
  }
  .navPanel ul.menu li {
    display: inline-block;
    vertical-align: top;
    border-bottom: none;
  }
  .navPanel .contact {
    text-align: right;
    top: 25px;
  }
  .navPanel .contact .tel {
    display: block;
    font-size: 24px;
    line-height: 1;
  }
  .navPanel .contact .dotted {
    margin: 0;
    font-size: 13px;
    line-height: 1.5;
    letter-spacing: 1px;
  }
  .navPanel .triggerSM {
    position: relative;
  }
  .navPanel .triggerSM.active {
    z-index: 10;
  }
  .navPanel .triggerSM .navItem {
    display: block;
  }
  .navPanel ul.subMenu {
    display: none;
    position: absolute;
    left: 0;
    top: 99%;
    background-color: rgba(23, 23, 23, 0.9);
    padding: 1rem;
    text-align: left;
  }
  .navPanel ul.subMenu li {
    width: 100%;
  }
  .navPanel ul.subMenu .navAnc {
    display: block;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 1340px) {
  .navPanel .navItem {
    padding: 0 1em;
  }
}

.promo {
  position: relative;
  margin-bottom: 2em;
  /*
  	.promoBoxTop { position:absolute; top:0; right:0; left:0;  background:rgba(23,23,23,0.5);
  		.container { position:relative; margin:0 auto; }
  		h1 { padding:0.4em 0; float:left; margin:0; text-shadow:0 1px 1px rgba(0,0,0,0.8); color:rgba(255,255,255,0.8); font:400 130%/1.1 $fontR; }
  		p { float: right; text-align:right; margin:0; }

  	}

  	.promoBox { display:none; position:absolute; right:0; bottom:2em; left:0;
  		.container { position:relative; margin:0 auto; }

  		p { float: right; text-align:right; margin:0; }
  		.btn { color:#fff; text-shadow:0 1px 1px rgba(0,0,0,0.8);
  			&:hover, &:active { color:#fff; }
  		}
  	}
  */
}
.promo:after {
  content: "";
  display: table;
  clear: both;
}
.promo .blackout {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(23, 23, 23, 0.5);
}
.promo .imgBox {
  position: relative;
  height: 0;
  padding-top: 36.46%;
}
.promo .imgBox img, .promo .imgBox video {
  display: block;
}
.promo .promoBoxRight {
  position: relative;
  padding: 20px;
  background: #171717;
}
.promo .promoBoxRight h1 {
  padding: 0;
  float: left;
  margin: 0;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.8);
  color: #fff;
  font: 400 150%/1.3 Roboto, sans-serif;
  margin-bottom: 0.75em;
}
.promo .promoBoxRight ol.listCN {
  margin: 0;
  padding: 0;
  list-style: none;
  counter-reset: list;
  padding-left: 2em;
  margin-bottom: 1.5em;
}
.promo .promoBoxRight ol.listCN > li {
  margin: 0;
  padding: 0;
  list-style: none;
  background: none;
}
.promo .promoBoxRight ol.listCN > li::before, .promo .promoBoxRight ol.listCN > li::after {
  content: none;
}
.promo .promoBoxRight ol.listCN li {
  position: relative;
  margin: 0 0 0.75em 0;
  list-style-type: none;
  line-height: 1.2;
  padding: 0.4em 0;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  padding-left: 0.5em;
  color: rgba(255, 255, 255, 0.8);
  text-transform: uppercase;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.8);
}
.promo .promoBoxRight ol.listCN li::before {
  counter-increment: list;
  content: counter(list);
  position: absolute;
  top: 0;
  right: 100%;
  width: 2em;
  height: 2em;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.7;
  font-weight: 400;
  text-align: center;
  border: 3px solid #e94f13;
  border-radius: 50%;
}
.promo .promoBoxRight ol.listCN li:hover {
  color: white;
}
.promo .promoBoxRight ol.listCN li:hover::before {
  color: white;
}
.promo .jquery-background-video-pauseplay {
  z-index: 3;
}
@media only screen and (min-width: 1220px) {
  .promo .blackout {
    background: rgba(23, 23, 23, 0.7);
  }
  .promo .promoBoxRight {
    position: absolute;
    top: 0;
    right: 0;
    left: 50%;
    padding: 1em 1em 1em 0;
    background: none;
  }
  .promo .promoBoxRight h1 {
    color: rgba(255, 255, 255, 0.8);
  }
}
@media only screen and (min-width: 1340px) {
  .promo .promoBoxRight {
    top: 1em;
  }
}
@media only screen and (min-width: 1900px) {
  .promo .promoBoxRight {
    top: 2em;
  }
}

.footer {
  position: relative;
  background: #171717;
  padding: 2em 0;
}
.footer .footerCols .footerLeftCol, .footer .footerCols .footerRightCol {
  margin-bottom: 0.5em;
}
.footer .footerTxt span.copy, .footer .footerTxt .linkFooter {
  display: block;
  margin-bottom: 0.25em;
  color: #9c9d9d;
  font-size: 0.889em;
  line-height: 1.33em;
}
.footer .footerTxt .linkFooter {
  font-size: 0.78em;
}
.footer .footerTxt a, .footer .footerTxt .dotted {
  color: #9c9d9d;
}
.footer .footerTxt a:hover, .footer .footerTxt .dotted:hover {
  color: #e94f13;
}
.footer .copy {
  display: block;
  white-space: nowrap;
  margin-bottom: 0.25em;
}
.footer .contact {
  position: relative;
  top: -2px;
}
.footer .contact .tel {
  display: inline-block;
  vertical-align: top;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  text-decoration: none;
  color: #fff;
  white-space: nowrap;
}
.footer .contact .tel .icon {
  display: inline-block;
  vertical-align: top;
  width: 24px;
  height: 24px;
  margin: 0 0.33em 0 0;
  background-color: red;
  background-position: 0 0;
}
.footer .contact .tel .pre {
  color: #9c9d9d;
}
.footer .contact .dotted {
  display: inline-block;
  vertical-align: top;
  margin-left: 1em;
  font: 400 11px/24px Roboto Condensed, sans-serif;
  text-transform: uppercase;
  color: #9c9d9d;
  white-space: nowrap;
}
.footer .contact .dotted:hover, .footer .contact .dotted:active {
  color: #fff;
}
@media only screen and (min-width: 768px) {
  .footer .footerCols {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
  .footer .footerCols .footerLeftCol {
    flex-grow: 1;
  }
  .footer .contact {
    text-align: right;
  }
  .footer .contact .tel {
    display: block;
  }
  .footer .contact .dotted {
    line-height: 1.5;
  }
}
@media only screen and (min-width: 1220px) {
  .footer .contact .tel {
    font-size: 24px;
    line-height: 1;
  }
  .footer .contact .dotted {
    margin: 0;
    font-size: 13px;
    letter-spacing: 1px;
  }
}

.infoCookies {
  display: none;
  position: fixed;
  z-index: 10;
  right: 5px;
  bottom: 5px;
  margin-left: 5px;
  background: rgba(240, 240, 240, 0.9);
  border: 2px solid #e94f13;
  padding: 0.75em;
  color: #444;
  text-align: center;
}
.no-hiddenscroll .noScroll .infoCookies {
  right: 22px;
}
.infoCookies h4 {
  text-align: center;
}
.infoCookies p {
  font-size: 0.889em;
}
@media only screen and (min-width: 768px) {
  .infoCookies {
    right: 10px;
    bottom: 10px;
    margin-left: 10px;
    padding: 30px;
  }
  .no-hiddenscroll .noScroll .infoCookies {
    right: 27px;
  }
}

.slider2 {
  margin: 0 -10px 2em -10px;
  padding: 0 10px;
}
.slider2 .slick-slide {
  margin: 0 10px;
}
.slider2 .slick-track {
  display: flex;
  align-content: stretch;
}
.slider2 .slick-arrow {
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  width: 20px;
  border: none;
  cursor: pointer;
  overflow: hidden;
  text-indent: -2000px;
  background: #fff;
}
.slider2 .slick-arrow:before, .slider2 .slick-arrow:after {
  content: "";
  position: absolute;
  width: 4px;
  height: 16px;
  background: #9c9d9d;
}
.slider2 .slick-arrow:before {
  top: auto;
  bottom: 50%;
}
.slider2 .slick-arrow:after {
  top: 50%;
  bottom: auto;
}
.slider2 .slick-arrow:hover:before, .slider2 .slick-arrow:hover:after {
  background: #e94f13;
}
.slider2 .slick-prev {
  left: 0;
}
.slider2 .slick-prev:before {
  left: 5px;
  transform: skew(-27deg, 0deg);
}
.slider2 .slick-prev:after {
  left: 5px;
  transform: skew(27deg, 0deg);
}
.slider2 .slick-next {
  right: 0;
}
.slider2 .slick-next:before {
  right: 5px;
  transform: skew(27deg, 0deg);
}
.slider2 .slick-next:after {
  right: 5px;
  transform: skew(-27deg, 0deg);
}
@media only screen and (min-width: 1024px) {
  .slider2 {
    padding: 0;
    margin: 0 -20px 2em -20px;
  }
  .slider2 .slick-slide {
    margin: 0 20px;
  }
}

.slider-aside {
  margin: 0 -10px 1em -10px;
}
.slider-aside .slick-slide {
  margin: 0 10px;
}
.slider-aside .slick-track {
  display: flex;
  align-content: stretch;
}
.slider-aside .slick-arrow {
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  width: 20px;
  border: none;
  cursor: pointer;
  overflow: hidden;
  text-indent: -2000px;
  background: #fff;
  outline: none;
}
.slider-aside .slick-arrow:before, .slider-aside .slick-arrow:after {
  content: "";
  position: absolute;
  width: 4px;
  height: 16px;
  background: #9c9d9d;
}
.slider-aside .slick-arrow:before {
  top: auto;
  bottom: 50%;
}
.slider-aside .slick-arrow:after {
  top: 50%;
  bottom: auto;
}
.slider-aside .slick-arrow:hover:before, .slider-aside .slick-arrow:hover:after {
  background: #e94f13;
}
.slider-aside .slick-arrow::-moz-focus-inner {
  border: 0;
}
.slider-aside .slick-prev {
  left: 0;
}
.slider-aside .slick-prev:before {
  left: 5px;
  transform: skew(-27deg, 0deg);
}
.slider-aside .slick-prev:after {
  left: 5px;
  transform: skew(27deg, 0deg);
}
.slider-aside .slick-next {
  right: 0;
}
.slider-aside .slick-next:before {
  right: 5px;
  transform: skew(27deg, 0deg);
}
.slider-aside .slick-next:after {
  right: 5px;
  transform: skew(-27deg, 0deg);
}
@media only screen and (min-width: 1024px) {
  .slider-aside {
    padding: 0;
    margin: 0 -20px 1em -20px;
  }
  .slider-aside .slick-slide {
    margin: 0 20px;
  }
}

.popupContent {
  display: none;
}

.blind {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  white-space: nowrap;
  text-align: center;
  background: rgba(23, 23, 23, 0.5);
}
.blind:before {
  content: "";
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
}

.popup {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  background: none;
  white-space: normal;
  text-align: left;
  cursor: default;
}
.popup .popupContent {
  position: relative;
  display: block;
  width: 100%;
  padding: 2em;
  background: #fff;
}
.popup .close {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
  padding: 10px;
  cursor: pointer;
}
.popup .close i {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
}
.popup .close i::before, .popup .close i::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  width: 2px;
  height: 100%;
  background: #9c9d9d;
}
.popup .close i:before {
  transform: skew(-45deg, 45deg);
}
.popup .close i:after {
  transform: skew(45deg, -45deg);
}
.popup .close:hover i::before, .popup .close:hover i::after {
  background: #e94f13;
}
.popup.popupGallery {
  min-width: 200px;
  min-height: 200px;
  max-width: 96%;
}
.popup.popupGallery .popupContent img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
}
.popup.popupGallery .popupContent .title {
  font-size: 14px;
  color: #171717;
  padding-top: 4px;
  text-align: center;
}
.popup.popupGallery .close {
  z-index: 3;
}
@media only screen and (min-width: 480px) {
  .popup {
    width: 90%;
    min-width: 320px;
  }
}
@media only screen and (min-width: 768px) {
  .popup {
    width: auto;
    min-width: 600px;
    max-width: 600px;
    padding: 20px 0;
  }
  .popup .popupContent {
    width: auto;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.9);
  }
  .popup .close {
    top: 20px;
  }
}

.arrowPopup {
  position: absolute;
  top: 0;
  display: block;
  width: 50%;
  height: 100%;
  cursor: pointer;
}
.arrowPopup i {
  position: absolute;
  top: 50%;
  width: 12px;
  height: 32px;
  margin-top: -16px;
}
.arrowPopup i::before, .arrowPopup i::after {
  content: "";
  position: absolute;
  width: 4px;
  height: 50%;
  background: #9c9d9d;
}
.arrowPopup i::before {
  top: auto;
  bottom: 50%;
}
.arrowPopup i::after {
  top: 50%;
  bottom: auto;
}
.arrowPopup.arrowL {
  left: 0;
}
.arrowPopup.arrowL i {
  left: 0.6em;
}
.arrowPopup.arrowL i:before {
  left: 3px;
  transform: skew(-27deg, 0deg);
}
.arrowPopup.arrowL i:after {
  left: 3px;
  transform: skew(27deg, 0deg);
}
.arrowPopup.arrowR {
  right: 0;
}
.arrowPopup.arrowR i {
  right: 0.6em;
}
.arrowPopup.arrowR i:before {
  right: 5px;
  transform: skew(27deg, 0deg);
}
.arrowPopup.arrowR i:after {
  right: 5px;
  transform: skew(-27deg, 0deg);
}
.arrowPopup:hover i::before, .arrowPopup:hover i::after, .arrowPopup:active i::before, .arrowPopup:active i::after {
  background: #e94f13;
}

.actionBox {
  display: block;
  position: relative;
  margin-bottom: 2em;
}
.actionBox:hover .btn {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  border-color: #e94f13;
}

a.actionBox, .actionBox.dotted {
  cursor: pointer;
}

.actionH {
  position: absolute;
  right: 3%;
  top: 7%;
  color: #fff;
  font-size: calc( 0.00625 * 100vw + 6px );
}
.actionH:after {
  content: "";
  display: table;
  clear: both;
}
.actionH h2 {
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  display: inline-block;
  font: 600 calc( 0.0225 * 100vw + 4.8px )/1.3 Montserrat Alternates, sans-serif;
  color: #e94f13;
  border-top: 1px solid #fff;
}
@media only screen and (min-width: 480px) {
  .actionH {
    right: 7%;
    top: 13.3%;
  }
}
@media only screen and (min-width: 768px) {
  .actionH h2 {
    border-top: 2px solid #fff;
  }
}

.actionTW {
  font-weight: 300;
  font-size: calc( 0.01 * 100vw + 3.8px );
  color: #fff;
  white-space: nowrap;
}

.actionStar {
  display: none;
}
@media only screen and (min-width: 768px) {
  .actionStar {
    display: inline;
  }
}

.actionOldPrice {
  position: relative;
  float: left;
  font: 500 calc( 0.011875 * 100vw + 4.2px )/1 Montserrat Alternates, sans-serif;
  margin: 1em 1em 1em 0;
}
.actionOldPrice::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #fff;
  transform: rotate(14deg);
  transform-origin: 100% 100%;
}

.actionPrice {
  float: right;
  font: 600 calc( 0.03875 * 100vw + 6.6px )/1.1 Montserrat Alternates, sans-serif;
  letter-spacing: -0.03em;
  color: #e94f13;
  border-bottom: 1px solid #fff;
}
@media only screen and (min-width: 768px) {
  .actionPrice {
    border-bottom: 2px solid #fff;
  }
}

.actionTxt {
  position: absolute;
  right: 3%;
  top: 62%;
}
.actionTxt p {
  margin-bottom: 1em;
}
@media only screen and (min-width: 480px) {
  .actionTxt {
    right: 7%;
  }
}
.actionTxt ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: right;
}
.actionTxt ul > li {
  margin: 0;
  padding: 0;
  list-style: none;
  background: none;
}
.actionTxt ul > li::before, .actionTxt ul > li::after {
  content: none;
}
.actionTxt ul li {
  margin-bottom: 0.5rem;
}
.actionTxt ul a, .actionTxt ul .dotted {
  color: #fff;
  text-decoration: none;
}
.actionTxt ul a:hover, .actionTxt ul .dotted:hover {
  color: #e94f13;
  text-decoration: underline;
}

.actionTD {
  font-weight: 500;
  font-size: calc( 0.01 * 100vw + 3.8px );
  color: #171717;
  white-space: nowrap;
}

.actionBtn {
  display: none;
  text-align: right;
}
@media only screen and (min-width: 768px) {
  .actionBtn {
    display: block;
  }
}

.actionFootnote {
  display: none;
  position: absolute;
  right: 7%;
  bottom: 3%;
  width: 93%;
  font-size: 0.85em;
  font-family: Roboto Condensed, sans-serif;
  text-align: right;
}
@media only screen and (min-width: 768px) {
  .actionFootnote {
    display: block;
  }
}

.actionBan {
  position: relative;
}
@media only screen and (min-width: 480px) {
  .actionBan .actionTxt {
    right: 4%;
  }
  .actionBan .actionH {
    top: 11%;
    right: 4%;
  }
}

.inStock {
  position: absolute;
  left: -5px;
  top: 7%;
  font: 400 1em/2em Roboto Condensed, sans-serif;
  padding: 0 1.5em;
  border-radius: 2px 1em 1em 0;
  background: #e94f13;
  color: #fff;
  text-transform: uppercase;
  border-top: 2px solid #eb602a;
}
.inStock::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: -4px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #994f13;
  transform: rotate(45deg);
}

.actionBox-02 .actionTxt {
  top: 56%;
}
.actionBox-02 .actionTD {
  color: #aaa;
  font-size: calc( 0.0075 * 100vw + 4.6px );
  margin-bottom: 0.125rem;
}
.actionBox-02 .actionTD strong {
  color: #9c9d9d;
}
@media only screen and (min-width: 768px) {
  .actionBox-02 .actionTxt {
    top: 62%;
  }
  .actionBox-02 .actionTD {
    font-size: calc( 0.00875 * 100vw + 4.2px );
    margin-bottom: 0.375rem;
  }
}
@media only screen and (min-width: 1024px) {
  .actionBox-02 .actionTD {
    margin-bottom: 0.5rem;
  }
}
.actionBox-02 .actionFootnote {
  color: #ccc;
}

.actionBox-03 .actionTD strong {
  color: #ccc;
}
.actionBox-03 .actionTW strong {
  font-size: 1.5em;
}
.actionBox-03 .actionPrice {
  float: right;
  font: 600 calc( 0.02625 * 100vw + 9.6px )/1.1 Montserrat Alternates, sans-serif;
  letter-spacing: -0.03em;
  color: #e94f13;
  border-bottom: 1px solid #fff;
}
@media only screen and (min-width: 768px) {
  .actionBox-03 .actionPrice {
    border-bottom: 2px solid #fff;
  }
}
.actionBox-03 .actionFootnote {
  color: #444;
}

.previewNews {
  display: block;
  text-decoration: none;
  cursor: pointer;
  color: #444;
}
.previewNews .img-preview {
  position: relative;
  display: block;
  margin-bottom: 0.5em;
  overflow: hidden;
  background: #444;
}
.previewNews .img-preview img {
  transition: transform ease-in-out 300ms;
}
.previewNews .box-preview {
  overflow: hidden;
  padding: 0 0.25em;
}
.previewNews .title-preview {
  margin: 0 0 0.25em;
}
.previewNews .title-preview h3 {
  color: #444;
  margin: 0;
}
.previewNews .time-preview {
  position: relative;
  top: -3px;
  color: #9c9d9d;
  font-size: 0.889em;
}
.previewNews .txt-preview {
  font-family: Roboto Condensed, sans-serif;
  font-weight: 300;
  transition: color ease-in-out 300ms;
}
.previewNews .txt-preview p {
  margin-bottom: 0.75em;
}
@media only screen and (min-width: 480px) {
  .previewNews .img-preview {
    float: left;
    width: 13em;
    margin-right: 0.75em;
  }
}
.previewNews:hover {
  color: #444;
  text-decoration: none;
}
.previewNews:hover .btn {
  color: #171717;
  border-color: #e94f13;
  background: rgba(255, 255, 255, 0.1);
}
.previewNews:hover .img-preview img {
  transform: scale(1.1);
}

.advantagesImg {
  margin-bottom: 2em;
}

.moreBan {
  position: relative;
  cursor: pointer;
  padding-top: 3em;
}
.moreBan img {
  opacity: 0.9;
  transition: opacity ease-in-out 300ms;
}
.moreBan .title {
  /*position:absolute; left:0; top:1em;*/
  position: relative;
  text-align: left;
}
.moreBan .title strong {
  display: block;
  font-family: Roboto Condensed, sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 150%;
  vertical-align: middle;
}
.moreBan .title .txt {
  display: block;
  margin: 0 1em 1em 0;
  background-color: rgba(255, 255, 255, 0.6);
  transition: background-color ease-in-out 300ms;
}
@media only screen and (min-width: 480px) {
  .moreBan {
    padding-top: 0;
  }
}
.moreBan:hover img {
  opacity: 1;
}
.moreBan:hover .title .txt {
  background: rgba(255, 255, 255, 0.8);
}

.big-moreBan {
  margin-bottom: 2em;
}
@media only screen and (min-width: 768px) {
  .big-moreBan .title {
    width: 50%;
  }
  .big-moreBan .title .txt {
    padding-right: 1em;
  }
}

.cardTxt h3 {
  margin-bottom: 0.25em;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.cardTxt h3 i {
  flex: 0 0 2em;
  display: inline-block;
  vertical-align: top;
  width: 2em;
  height: 2em;
  margin-right: 0.5em;
  border: 0.25em solid #e94f13;
  border-radius: 50%;
  text-align: center;
  opacity: 0.8;
  background: #fff;
}
.cardTxt h3 i::after {
  content: "+";
  font: normal 400 1.95em/0.77em Roboto, sans-serif;
}
.cardTxt p {
  margin-bottom: 0;
  margin-top: 0.5em;
}

.series {
  display: block;
  text-decoration: none;
  position: relative;
  height: 100%;
  cursor: pointer;
  border: 1px solid #ebebeb;
  padding: 0 1em 1em 1em;
  /*@include respond(tabletL){
  	.options-series { position:absolute; left:0; top:0;
  		.title-series { position:relative; }
  		ul { margin-right:0;  margin-left:0; font-size:0.778em;
  			li { background:rgba(255,255,255,0.6); }
  		}
  	}
  	&:hover {
  		.options-series {
  			.title-series { opacity:1;}
  			ul {
  				li {background:rgba(255,255,255,0.9); }
  			}
  		}
  	}
  }*/
}
.series .img-series {
  overflow: hidden;
  margin: 0 -1em 0.5em;
}
.series .img-series img {
  margin: 0 auto;
  opacity: 0.9;
  transform: scale(1);
  /*transform:scale(0.95);*/
  transition: opacity ease-in-out 300ms, transform ease-in-out 300ms;
}
.series .txt-series {
  padding-bottom: 30px;
  font-family: Roboto Condensed, sans-serif;
}
@media only screen and (min-width: 1220px) {
  .series .txt-series {
    padding-bottom: 40px;
  }
}
.series .txt-series p, .series .txt-series ul {
  margin-bottom: 0.5em;
  color: #444;
}
.series .rowBtn {
  position: absolute;
  left: 1em;
  right: 1em;
  bottom: 1em;
  text-align: right;
  margin: 0;
}
.series .options-series {
  position: static;
  margin-bottom: 0.5em;
}
.series .options-series .title-series {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0.25em 1em;
  margin: -1px 0 2px -1px;
  background: #eb602a;
  opacity: 0.95;
  transition: opacity ease-in-out 300ms;
}
.series .options-series .title-series h3 {
  margin: 0;
  padding: 0;
  font: 300 1.11em/1.2 Roboto Condensed, sans-serif;
  color: #fff;
  text-transform: uppercase;
}
.series .options-series .title-series h3 .sn {
  font-weight: 400;
  font-size: 1.22em;
  margin-left: 0.25em;
  font-family: Roboto, sans-serif;
  text-transform: none;
}
.series .options-series ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font: 300 0.889em/1 Roboto Condensed, sans-serif;
  color: #444;
  transition: color ease-in-out 300ms;
  margin-right: -1em;
  margin-left: -1em;
}
.series .options-series ul > li {
  margin: 0;
  padding: 0;
  list-style: none;
  background: none;
}
.series .options-series ul > li::before, .series .options-series ul > li::after {
  content: none;
}
.series .options-series ul li {
  padding: 0.33em 1em 0.33em 1em;
  background: rgba(245, 245, 245, 0.5);
  margin-bottom: 2px;
  transition: background ease-in-out 300ms;
}
.series .options-series ul li strong {
  font-weight: 400;
}
.series:hover .img-series img {
  opacity: 1;
  transform: scale(1.05);
}
.series:hover .options-series ul {
  color: #171717;
}

.img-seriesFA {
  display: block;
  max-width: 100%;
  margin: 0 auto 1em;
}
@media only screen and (min-width: 480px) {
  .img-seriesFA {
    /*max-width:80%;*/
    max-width: 100%;
  }
}
.img-seriesFA img {
  display: block;
  min-width: 100%;
  max-width: 100%;
  height: auto;
}

.anchorSeries {
  width: 0;
  height: 0;
  position: relative;
  top: -20px;
}
@media only screen and (min-width: 768px) {
  .anchorSeries {
    top: -40px;
  }
}

.itemSeries {
  /*margin-top:4em;*/
  margin-top: 3em;
}
.itemSeries:first-child {
  margin-top: 0;
}

.preview-article figure {
  position: relative;
  display: block;
  overflow: hidden;
  padding-top: 150%;
  max-width: 100%;
}
.preview-article img {
  position: absolute;
  left: 50%;
  top: 0;
  display: block;
  width: auto;
  height: 100%;
  max-height: 100%;
  transform: translateX(-50%);
}
.preview-article figcaption {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 1em;
  color: #fff;
  text-align: center;
}
.preview-article figcaption h3 {
  color: #fff;
  font-size: 1.22em;
  text-transform: none;
  text-align: left;
  opacity: 0.8;
  transition: opacity ease-in-out 300ms;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.8);
}
.preview-article .blackout {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(23, 23, 23, 0.7) 0%, rgba(23, 23, 23, 0.1) 100%);
}
.preview-article .layerH {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  height: 100%;
  background: rgba(23, 23, 23, 0.6);
  /*background:rgba(233,79,19,0.8);*/
  padding: 1em;
  transition: all ease-in-out 300ms;
}
.preview-article .layerH .btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.preview-article .tags {
  display: block;
  padding: 0 0.5em;
}
.preview-article:hover h3, .preview-article:active h3 {
  opacity: 1;
}
.preview-article:hover .layerH, .preview-article:active .layerH {
  top: 0;
}

.columnsContent {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
.columnsContent .columnL, .columnsContent .columnR {
  padding-bottom: 2em;
}
.columnsContent .readAlso {
  position: relative;
  max-width: 100%;
}
@media only screen and (min-width: 1024px) {
  .columnsContent {
    flex-flow: row nowrap;
  }
  .columnsContent .columnL, .columnsContent .columnR {
    flex: 1 1 auto;
    padding-bottom: 2em;
  }
  .columnsContent .columnL {
    margin: 0 3em 0 0;
  }
  .columnsContent .columnR {
    flex: 0 0 25%;
    max-width: 25%;
    padding-top: 3.3em;
  }
  .columnsContent .readAlso {
    position: sticky;
    top: 1em;
  }
}

.autorBlock {
  border-top: 3px solid #ebebeb;
  text-align: right;
  padding-top: 0.5em;
  text-align: left;
}
.autorBlock:after {
  content: "";
  display: table;
  clear: both;
}

.autor-article {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  /*border:1px solid $clrBrdr; background:$clrGrayW; padding:0.5em; max-width:50%;*/
}
.autor-article .avatar-autor {
  flex: 0 0 auto;
  width: 3em;
  height: 3em;
  margin-right: 0.5em;
  border-radius: 50%;
  border: 3px solid #e94f13;
  background: #9c9d9d;
  overflow: hidden;
}
.autor-article .name-autor {
  flex: 1 1 auto;
}
.autor-article .name-autor strong {
  display: block;
  color: #444;
  font: 400 1em/1 Roboto Condensed, sans-serif;
}
.autor-article .name-autor span {
  color: #9c9d9d;
  font: 300 0.778em/1 Roboto Condensed, sans-serif;
}
@media only screen and (min-width: 1024px) {
  .autor-article {
    max-width: none;
  }
}

.headerArticle {
  margin: 2.5em 0 1em 0;
}
.headerArticle:after {
  content: "";
  display: table;
  clear: both;
}
.headerArticle .autor-article {
  float: right;
}
.headerArticle h1 {
  margin: 0 0 0.25em 0;
  clear: both;
  text-transform: none;
  text-align: left;
}

.anons {
  margin-bottom: 2em;
}
.anons .txt-anons p, .anons .txt-anons ul {
  margin: 0;
}
@media only screen and (min-width: 480px) {
  .anons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }
  .anons .img-anons {
    width: 33.33%;
    flex: 0 0 auto;
    margin-right: 2em;
  }
  .anons .txt-anons {
    flex: 1 1 auto;
    font-size: 110%;
    font-weight: 700;
  }
}

.gagArticle {
  background: #ebebeb;
  height: 100%;
}

ul.certificates .wrapImg {
  position: relative;
  display: block;
  border: 1px solid #ebebeb;
  overflow: hidden;
  margin-bottom: 0.5em;
  transition: border-color ease-in-out 300ms;
}
ul.certificates figcaption {
  color: #9c9d9d;
  font-size: 0.889em;
  font-family: Roboto Condensed, sans-serif;
  text-align: center;
  transition: color ease-in-out 300ms;
}
ul.certificates .layerH {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  height: 100%;
  background: rgba(23, 23, 23, 0.4);
  transition: all ease-in-out 300ms;
}
ul.certificates .layerH .btn {
  position: absolute;
  left: 50%;
  top: 50%;
  display: block;
  width: auto;
  transform: translate(-50%, -50%);
}
ul.certificates a, ul.certificates .dotted {
  display: block;
  text-decoration: none;
}
ul.certificates a:hover .wrapImg, ul.certificates .dotted:hover .wrapImg {
  border: 1px solid #444;
}
ul.certificates a:hover figcaption, ul.certificates .dotted:hover figcaption {
  color: #444;
}
ul.certificates a:hover .layerH, ul.certificates .dotted:hover .layerH {
  top: 0;
}

.brdO {
  border: 1px solid #fabeaa;
}

ul.listContact {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 1em;
  font-size: 90%;
  line-height: 1.5;
}
ul.listContact > li {
  margin: 0;
  padding: 0;
  list-style: none;
  background: none;
}
ul.listContact > li::before, ul.listContact > li::after {
  content: none;
}
ul.listContact li {
  margin-bottom: 0.5em;
  display: flex;
  flex-flow: row nowrap;
  flex: auto;
}
ul.listContact li.marginUp {
  margin-top: -0.5em;
}
ul.listContact span.label {
  flex: 0 0 auto;
  margin-right: 0.5em;
  font-family: Roboto Condensed, sans-serif;
  color: #9c9d9d;
}
ul.listContact strong {
  flex: 1 1 auto;
  color: #444;
  font-weight: 400;
}
ul.listContact strong.big {
  font-weight: 400;
  font-size: 110%;
}
ul.listContact .small {
  display: block;
  font-size: 80%;
  color: #444;
}
ul.listContact a, ul.listContact .dotted {
  text-decoration: none;
  color: #444;
}
ul.listContact a:hover, ul.listContact .dotted:hover {
  text-decoration: underline;
}
.contactsBox {
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  border-left: 0.2rem solid #ebebeb;
}
.contactsBox h5 {
  font-weight: 400;
  font-size: 0.95em;
}
.contactsBox ul.listContact {
  margin-bottom: 0;
}
.contactsBox ul.listContact li:last-child {
  margin-bottom: 0;
}
.contactsBox .tel {
  text-decoration: none;
  color: #444;
  font-weight: 400;
  font-size: 110%;
}
.contactsBox .tel:hover {
  text-decoration: underline;
}

.contactsIndent {
  display: block;
  clear: both;
  height: 0.5rem;
  width: 100%;
}

.boxFeature {
  margin-bottom: 0.5em;
  padding: 1em 0;
}

.feature {
  display: block;
  text-decoration: none;
  position: relative;
  height: 100%;
  cursor: pointer;
  border: 1px solid #ebebeb;
  padding: 0 1em 1em 1em;
}
.feature .img-feature {
  overflow: hidden;
  margin: 0 -1em 0.5em;
}
.feature .img-feature img {
  margin: 0 auto;
}
.feature .txt-feature {
  font-family: Roboto Condensed, sans-serif;
}
.feature .txt-feature p, .feature .txt-feature ul {
  margin-bottom: 0.5em;
  color: #444;
}