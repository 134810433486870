.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
}

.icon-arrow {
  font-size:(8/10)*1rem;
  width:(14/8)*1em;
}
.icon-bodor_logo {
  font-size:(283.4/10)*1rem;
  width:(734.1/283.4)*1em;
}
