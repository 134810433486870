@mixin grid(
	$grid_colName:w,
	$grid_cols: 12
) {
	$grid_width: 100%;
	$grid_colWidth: $grid_width / $grid_cols;

	@for $i from 1 through $grid_cols {
		.#{$grid_colName}_#{$i} {
			width: $i * $grid_colWidth;
		}
	}
}
@include grid();
@include respond(phoneL) { @include grid(wPL, 12); };
@include respond(tabletS) { @include grid(wTS, 12); };
@include respond(tabletL) { @include grid(wTL, 12); };
@include respond(desktopS) { @include grid(wDS, 12); };



.grid { @include listR(); clear:both;  margin: 0 -10px; display:flex; flex-flow:row wrap; align-items:stretch;
  >.boxGrid { flex:0 1 100%; position:relative;  margin-bottom:20px; border-right:10px solid transparent; border-left:10px solid transparent; background-clip:padding-box; }
	@include respond(tabletS) { margin: 0 -20px;
		.boxGrid { margin-bottom:40px; border-right:20px solid transparent; border-left:20px solid transparent; }
	}
}

.grid-4 {
  >.boxGrid { flex:0 1 50%; }
  @include respond(tabletS) {  margin: 0 -10px;
    >.boxGrid { flex:0 1 25%; margin-bottom:20px; border-right:10px solid transparent; border-left:10px solid transparent; }
  }
}
.grid-3 {  margin: 0 -10px;
	>.boxGrid { flex:0 1 100%;  }
	@include respond(tabletS) {
		>.boxGrid { flex:0 1 50%; margin-bottom:20px; border-right:10px solid transparent; border-left:10px solid transparent; }
	}
	@include respond(desktopS) {
		>.boxGrid { flex:0 1 33.33%; }
	}
}
.grid-2 {
  >.boxGrid { flex:0 1 100%; }
  @include respond(tabletL) {
    >.boxGrid {  flex:0 1 50%; }
  }
}
